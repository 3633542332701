import { withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { IHeaderIcon } from "@components/header/Header";
import { EditLockIcon } from "@components/icon";
import { formatDateToDateString, getWorkDateFromLocalStorage } from "@components/inputs/date/utils";
import { TSelectItemId } from "@components/inputs/select/Select.types";
import { IGetValueArgs, isFieldDisabled, isVisible, isVisibleByPath } from "@components/smart/FieldInfo";
import { getValidVats } from "@components/smart/GeneralFieldDefinition";
import { getCollapsedGroupId } from "@components/smart/Smart.utils";
import { ActionType, addNewLineItem, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { ODataError } from "@odata/Data.types";
import { getBoundValue, setNestedValue } from "@odata/Data.utils";
import { getFieldTypeFromProperty, getValidatorTypeFromProperty, IFieldInfo } from "@odata/FieldInfo.utils";
import {
    BankAccountEntity,
    BillingAddressEntity,
    BusinessPartnerEntity,
    DocumentBusinessPartnerEntity,
    DocumentCbaCategoryEntity,
    DocumentDraftEntity,
    DocumentEntity,
    DocumentItemEntity,
    DocumentItemVatClassificationSelectionEntity,
    DocumentVatClassificationEntity,
    DocumentVatClassificationSelectionEntity,
    ElectronicSubmissionEntity,
    EntitySetName,
    EntityTypeName,
    IBusinessPartnerEntity,
    IDocumentDraftEntity,
    IDocumentDraftExtractIsdocParameters,
    IDocumentEntity,
    IElectronicSubmissionEntity,
    IFileMetadataEntity,
    IInternalDocumentItemEntity,
    InvoiceIssuedEntity,
    InvoiceReceivedEntity,
    IProformaInvoiceReceivedEntity,
    IRegularDocumentItemEntity,
    OdataActionName,
    RegularDocumentItemEntity,
    SelectionEntity,
    VatClassificationEntity,
    VatClassificationSelectionEntity
} from "@odata/GeneratedEntityTypes";
import {
    ActionTypeCode,
    CompanyPermissionCode,
    DocumentLinkTypeCode,
    DocumentTypeCode,
    FiscalYearStatusCode,
    LanguageCode,
    PayableReceivableTypeCode,
    PaymentMethodCode,
    SelectionCode,
    VatCode,
    VatReverseChargeCode
} from "@odata/GeneratedEnums";
import { BatchRequest, EntitySetWrapper, IBatchResult } from "@odata/OData";
import { getDefaultLogActionDetail, logAction } from "@odata/OData.utils";
import {
    handleItemTemplateChange,
    invalidateItemTemplateSelectItems
} from "@pages/admin/itemTemplates/ItemTemplates.utils.shared";
import { BusinessPartnerSaveErrors } from "@pages/businessPartner/BusinessPartner.shared.utils";
import VatLockOverrideConfirmDialog from "@pages/documents/VatLockOverrideConfirmDialog";
import { handleBankAccountOrIBANBlur } from "@utils/BankUtils";
import {
    getCompanyCurrency,
    isAccountAssignmentCompany,
    isCashBasisAccountingCompany,
    isVatRegisteredCompany
} from "@utils/CompanyUtils";
import { ICopyEntityArgs } from "@utils/DraftUtils";
import { isDefined, isObjectEmpty, roundToDecimalPlaces } from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import { logger } from "@utils/log";
import { getOneFetch } from "@utils/oneFetch";
import { checkPermissionWithOwnPermission } from "@utils/permissionUtils";
import Big from "big.js";
import { saveAs } from "file-saver";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";

import BusyIndicator from "../../components/busyIndicator";
import { Button } from "../../components/button";
import { AppContext, ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import { withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { FormMode, PageViewMode, Status, ValidationErrorType, ValidatorType } from "../../enums";
import { EmptyObject, TRecordAny } from "../../global.types";
import { IValidationResult, ModelEvent } from "../../model/Model";
import { Validator } from "../../model/Validator";
import BindingContext, { createPath, IEntity, TEntityPropValue } from "../../odata/BindingContext";
import DateType, { getUtcDayjs } from "../../types/Date";
import NumberType from "../../types/Number";
import FileStorage from "../../utils/FileStorage";
import memoizeOne from "../../utils/memoizeOne";
import { APPLY_ISDOC_ACTION, APPLY_ROSSUM_ACTION } from "../../views/fileView/FileViewUtils";
import DraftFormView from "../../views/formView/DraftFormView";
import { getAlertFromError } from "../../views/formView/Form.utils";
import {
    FormStorage,
    IContextInitArgs,
    IFormStorageSaveResult,
    IGetCorrectErrorBc,
    ISaveArgs,
    IValidateAndSave,
    TCustomResponseHandler,
    TShouldUseCustomResHandler
} from "../../views/formView/FormStorage";
import TemporalFormDialog from "../../views/formView/TemporalFormDialog";
import {
    applyAccountFromTemplate,
    correctAccountIdAfterChange,
    correctAccountIds,
    getCurrentCoAId,
    handleAccAssignmentChange,
    handleAccAssignmentChildrenChange,
    handleItemAccAssignmentChange,
    handleItemAccAssignmentDialog,
    invalidateAccountAssignments,
    prepareAccountAssignmentsForSave,
    processAccountAssignmentSelection,
    refreshAccountIdsForActualFiscalYear,
    sendAdditionalRequestForAccountAssignment,
    setCorrectSpecialItemsForAccountAssignment
} from "../accountAssignment/AccountAssignment.utils";
import { AccountAssignmentDialog } from "../accountAssignment/AccountAssignmentDialog";
import {
    AUTOMATED_VAT_INCLUSION_MODE_PATH,
    clearVatClassificationFields,
    correctDataAfterVatChildrenChangeOnDocument,
    correctSelectionCode,
    correctVatDeduction,
    correctVatIds,
    correctVatSelectionFields,
    getClassificationCodeFromCountry,
    getSelectionCodeDependentField,
    getVatAssignmentGroupId,
    getVatConfigOptions,
    getVatDependentFields,
    getVatGroupRowsDef,
    handleCbaCategoryChange,
    hasVatProportionalDeduction,
    isDocumentReverseCharged,
    isInAutomatedVatInclusionMode,
    isProportionalDeduction,
    isVatRegisteredEntity,
    ITEMS_VAT_DEDUCTION_PATH,
    IVatConfigOptions,
    SAVED_VATS_PATH,
    VAT_CLASSIFICATION_PATH,
    VAT_REVERSE_CHARGE_SELECT_PATH
} from "../admin/vatRules/VatRules.utils";
import { withMinorAssetPairing } from "../asset/minorAsset/withMinorAssetPairing";
import {
    addBusinessPartnerBankAccountRequest,
    addCreateReceiptRequest,
    CREATE_RECEIPT_PATH,
    deleteEmptyBankAccount,
    getBankBlurArgs,
    handleBankFieldChange,
    handleCreateReceiptsChange,
    handlePaymentMethodChange,
    IBankAccountArgs,
    prepareSavedAccounts,
    SAVED_ACCOUNTS_PATH,
    setVariableSymbolFromNumberOurs,
    tryToCreateIBAN,
    tryToCreateSwift
} from "../banks/bankAccounts/BankAccounts.utils";
import {
    addBusinessPartnerContactRequest,
    handleBusinessPartnerBlur,
    handleBusinessPartnerChange,
    isBusinessPartnerField,
    isNewPartnerWithData,
    prepareNewBusinessPartner,
    setBusinessPartnerGroupStatus,
    setDefaultsFromBusinessPartner,
    setSavedContactItems
} from "../businessPartner/BusinessPartner.utils";
import {
    CBA_CATEGORY_PATH,
    handleCustomCbaCategoryChange,
    handleItemCbaCategoryChange,
    loadCategoriesMap,
    setCbaDefaultValuesForNotVisibleFields,
    setCorrectSpecialItemsForItemCategory
} from "../cashBasisAccounting/CashBasisAccounting.utils";
import CustomCategoryTemporalDialog from "../cashBasisAccounting/CustomCategoryTemporalDialog";
import { setMatchingFiscalPeriod } from "../fiscalYear/FiscalYear.utils";
import RossumDialog from "../inbox/RossumDialog";
import RossumProgressDialog from "../inbox/RossumProgressDialog";
import {
    replaceWildcards,
    replaceWildcardsAfterDateChange,
    setMatchingNumberRange
} from "../numberRange/NumberRange.utils";
import {
    _getId,
    anyPathEquals,
    calcLineItemValues,
    DefaultDueDays,
    DRAFT_ITEM_ID_PATH,
    FORCE_VAT_LOCK_OVERRIDE_DATE_PARAM,
    FORCE_VAT_LOCK_OVERRIDE_PARAM,
    getDocumentLinkBcs,
    getExchangeRate,
    getFiscalDataCorrespondingToDateAccountingTransaction,
    getInvoicePdfUrl,
    getVatItemsDecisiveDateName,
    hasCorrectiveDocument,
    hasMetadataRule,
    hasSavedDraft,
    isReceived,
    IssuedDocumentTypes,
    lineItemsTriggerProps,
    loadVats,
    MetadataLockType,
    refreshExchangeRate,
    setDefaultLabelsFromEntityToItems,
    setLockBreadcrumbs,
    validateDecisiveDate
} from "./Document.utils";
import { DocumentDateGroupRowsAndOrder } from "./DocumentDef";
import { IDocumentExtendedEntity, IDocumentFormViewProps } from "./DocumentInterfaces";
import { withAccruals } from "./extensions/accruals/withAccruals";
import { ItemsSummariesPaths } from "./extensions/itemsSummary/ItemsSummary.utils";
import { loadRelatedProformas, RELATED_PROFORMA_ADDITIONAL_RESULT_IDX } from "./extensions/proforma/Proforma.utils";
import { withTimeResolution } from "./extensions/timeResolution/withTimeResolution";
import SendInvoiceDialog from "./SendInvoiceDialog";

// for DocumentItem entity type
const lineItemsForceValidatePropsDocItem = [
    "TransactionUnitPrice", "TransactionUnitPriceVat", "TransactionUnitPriceNet"
];

// for RegularDocumentItem entity type => not used for Internal Document
const lineItemsForceValidatePropsRegularDocItem = [
    "TransactionAmount", "TransactionAmountNet", "TransactionAmountVat"
];

type TDocumentCurrencyDependentProps =
    "Amount"
    | "AmountNet"
    | "AmountVat"
    | "UnitPrice"
    | "UnitPriceNet"
    | "UnitPriceVat";
type TDocumentTransactionProps =
    "TransactionAmount"
    | "TransactionAmountNet"
    | "TransactionAmountVat"
    | "TransactionUnitPrice"
    | "TransactionUnitPriceNet"
    | "TransactionUnitPriceVat";

const currencyDependentFieldsDocItem: TDocumentCurrencyDependentProps[] = ["AmountNet", "AmountVat"];
const currencyDependentFieldsRegularDocItem: TDocumentCurrencyDependentProps[] = ["UnitPrice", "UnitPriceNet", "UnitPriceVat"];

const lineItemsRecalculateCheckProps = ["TransactionAmount", "TransactionAmountNet", "TransactionAmountVat"];

class DocumentFormView<P extends IDocumentFormViewProps = IDocumentFormViewProps> extends DraftFormView<IDocumentExtendedEntity, P> {
    static contextType = AppContext;
    // sadly, breaks typescript type checking
    // context: React.ContextType<typeof AppContext>;

    documentTypeCode: DocumentTypeCode;
    _copyAmountFromDraft = true;

    get _documentItemDomainType(): string {
        return this.documentTypeCode === DocumentTypeCode.InternalDocument ? EntityTypeName.InternalDocumentItem : EntityTypeName.RegularDocumentItem;
    }

    // when recalculating line items value, there can be deviations in the results because we only round value to two decimal places
    // we want the last changed value to remain the same as is currently in the input when quantity/vat rate is changed
    _lineItemsLastChanged: string = null;
    _lastChangedLineItemId: number = null;

    constructor(props: P) {
        super(props);

        this.onAfterLoad = this.onAfterLoad.bind(this);
        this.renderSaveButtons = this.renderSaveButtons.bind(this);
        this.getSuccessMessage = this.getSuccessMessage.bind(this);
        this.setMatchingNumberRangeForDocument = this.setMatchingNumberRangeForDocument.bind(this);
        this.refreshFormAfterDecisiveDateChange = this.refreshFormAfterDecisiveDateChange.bind(this);
        this.documentSpecificChangeHandlers = this.documentSpecificChangeHandlers.bind(this);
        this.documentSpecificBlurHandlers = this.documentSpecificBlurHandlers.bind(this);
        this.handleVatItemsDecisiveDateChange = this.handleVatItemsDecisiveDateChange.bind(this);
        this.confirmationBeforeSave = this.confirmationBeforeSave.bind(this);
    }

    get isPostable(): boolean {
        return this.hasAccountAssignment;
    }

    get isRegularDocumentItem(): boolean {
        return this.documentTypeCode !== DocumentTypeCode.InternalDocument;
    }

    // for proforma, we want to keep Amount (with Vat) as default when recalculating prices
    get defaultItemLastChangedProp(): keyof IRegularDocumentItemEntity {
        return null;
    }

    // todo: keyof T where T is type, which is handled by the FormView
    get datePropForNumberRange(): string {
        if (isCashBasisAccountingCompany(this.context)) {
            return this.isReceivedDoc() ? InvoiceReceivedEntity.DateReceived : InvoiceIssuedEntity.DateIssued;
        }
        return DocumentEntity.DateAccountingTransaction;
    }

    get datePropForCurrencyExchangeRate(): string {
        if (isCashBasisAccountingCompany(this.context)) {
            if (isVatRegisteredCompany(this.context)) {
                return this.isReceivedDoc() ? InvoiceReceivedEntity.DateVatDeduction : InvoiceIssuedEntity.DateTaxableSupply;
            }
            return this.isReceivedDoc() ? InvoiceReceivedEntity.DateReceived : InvoiceIssuedEntity.DateIssued;
        }
        return DocumentEntity.DateAccountingTransaction;
    }

    get hasAccountAssignment(): boolean {
        return isAccountAssignmentCompany(this.context);
    }

    get isCashBasisAccountingCompany(): boolean {
        return isCashBasisAccountingCompany(this.context);
    }

    get saveButtonTranslation(): string {
        const { storage } = this.props;
        const isNew = storage.data.bindingContext.isNew();
        const isSavedWithoutChanges = this.isSavedWithoutChanges();
        const newOrNotDirty = isNew || isSavedWithoutChanges;
        let key;

        if (this.hasAccountAssignment && this.isPostable) {
            key = newOrNotDirty ? "SaveAndPost" : "ChangeAndPost";
        } else {
            key = newOrNotDirty ? "Save" : "Change";
        }

        return storage.t(`Document:Buttons.${key}`).toString();
    }

    // For documents forms, we use preventAutoReset to prevent SmartODataTableBase from auto resetting,
    // to prevent wrong and multiple fetches, caused when binding context changes (new row is selected/copying/adding new row)

    shouldComponentUpdate(nextProps: Readonly<IDocumentFormViewProps>, nextState: Readonly<EmptyObject>): boolean {
        return this.props.storage?.formMode === FormMode.AuditTrail || nextState !== this.state;
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.props.storage.emitter.on(ModelEvent.CustomFileAction, this.handleCustomFileAction);
        this.props.storage.emitter.on(ModelEvent.FilesUploaded, this.handleFilesUploaded);
        this.props.storage.emitter.on(ModelEvent.RequestCreateDraft, this.handleRequestCreateDraft);
        this.registerHeader();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.storage.emitter.off(ModelEvent.CustomFileAction, this.handleCustomFileAction);
        this.props.storage.emitter.off(ModelEvent.FilesUploaded, this.handleFilesUploaded);
        this.props.storage.emitter.off(ModelEvent.RequestCreateDraft, this.handleRequestCreateDraft);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<EmptyObject>): void {
        super.componentDidUpdate(prevProps, prevState);
        this.registerHeader();
    }

    registerHeader = (): void => {
        if (this.props.storage.data.bindingContext) {
            const bc = this.props.storage.data.bindingContext.navigate("Currency");
            this.props.storage.addCustomRef(this._refHeader.current, bc);

            const numberOursBc = this.props.storage.data.bindingContext.navigate("NumberOurs");
            this.props.storage.addCustomRef(this._refHeader.current, numberOursBc);
        }
    };

    isReceivedDoc = (): boolean => {
        return isReceived(this.documentTypeCode);
    };

    refreshDateDue = (dueDays?: number): void => {
        const { storage } = this.props;
        const date = storage.getValueByPath(InvoiceIssuedEntity.DateIssued);
        const daysDuePath = `BusinessPartner/BusinessPartner/${this.isReceivedDoc() ? "Received" : "Issued"}DocumentDefault/DaysDue`;
        if (isDefined(dueDays)) {
            // when BP is changed, this method is called with param - default dueDays of new BP.
            // we need set the value to storage, so we can use it later when date has changed.
            storage.setValueByPath(daysDuePath, dueDays);
        } else {
            dueDays = storage.getValueByPath(daysDuePath);
        }

        const dueDate = getUtcDayjs(date).add(dueDays ?? DefaultDueDays, "day");

        if (dueDate.isValid()) {
            this.props.storage.setValueByPath("DateDue", dueDate.toDate());
        }
    };

    handleKeyboardShortcut(shortcut: KeyboardShortcut, event: KeyboardEvent): boolean {
        if (!this.isEntityReady) {
            return false; // no shortcuts when entity is not yet ready
        }

        if (this.props.storage.pageViewMode === PageViewMode.FormReadOnly) {
            return super.handleKeyboardShortcut(shortcut, event);
        }

        if (shortcut === KeyboardShortcut.ALT_C) {
            if (this.shouldAllowCopy() && !this.shouldDisableCopyButton() && !this.props.storage.isBusy()) {
                this.handleCopy();

                return true;
            }

            return false;
        } else if (shortcut === KeyboardShortcut.ALT_S) {
            if (this.isPostable && !this.shouldDisableSavePostButton()) {
                this.handleSaveAndPostClick();
                return true;
            } else if (!this.isPostable && !this.shouldDisableSaveButton()) {
                this.handleSaveClick();
                return true;
            } else if (!this.preventDraftSave && !this.shouldDisableDraftButton()) {
                this.handleSaveDraft();
                return true;
            }

            return false;
        } else {
            return super.handleKeyboardShortcut(shortcut, event);
        }
    }

    getAppContext = (): IAppContext => {
        return this.context;
    };

    _proformaOneFetch = getOneFetch();
    getAdditionalLoadPromise = (args: IContextInitArgs): (Promise<any> | void)[] => {
        const { oData } = this.props.storage;
        const promises: (Promise<any> | void)[] = [
            loadRelatedProformas(args.bindingContext, oData, null, this.isAuditTrail ? null : this._proformaOneFetch.fetch), // keep on RELATED_PROFORMA_ADDITIONAL_RESULT_IDX index
            loadVats(this.getAppContext(), this.props.storage)
        ];
        return promises;
    };

    async onAfterLoad(hasPreloadedData?: boolean): Promise<void> {
        const { storage } = this.props;
        const entity = storage.data.entity;
        const domManipulatorContext = this.props.domManipulatorOrchestrator.createScope();

        const promises: Promise<unknown>[] = [];

        const hasVatClassifications = isVisibleByPath(storage, SAVED_VATS_PATH);

        if (storage.formMode !== FormMode.AuditTrail) {

            const isNew = storage.data.bindingContext.isNew();
            const isDraft = this.isDraftView();
            // DocumentTypeCode needs to be set as early as possible, e.g. correctVatSelectionFields works with this
            entity.DocumentTypeCode = this.documentTypeCode;

            if (isDraft && !isObjectEmpty(entity.BusinessPartner) && !entity.BusinessPartner?.BusinessPartner?.Id) {
                const existingBP = await this.getExistingBusinessPartner(this.entity, this.props.storage);
                if (existingBP?.Id) {
                    const bpPath = createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner);
                    storage.setValueByPath(bpPath, existingBP);
                }
            }

            const businessPartner = entity.BusinessPartner?.BusinessPartner;
            // Payment section with savedAccounts is conditional and not visible on all documents.
            if (storage.data.definition.fieldDefinition[SAVED_ACCOUNTS_PATH]) {
                prepareSavedAccounts({
                    account: this.props.storage.data.entity.BankAccount,
                    storage,
                    type: this.documentTypeCode,
                    businessPartner,
                    isInit: true
                });
            }

            if (this.isCashBasisAccountingCompany) {
                // this has to be before any await as onAfterLoad is called from storage init after infos are loaded
                // and just before creating validation schema
                const path = storage.data.bindingContext.navigate(this.datePropForNumberRange).getFullPath(true);
                const info = storage.data.fieldsInfo[path];
                storage.data.fieldsInfo[path] = {
                    ...info,
                    validator: {
                        type: ValidatorType.Custom,
                        settings: {
                            customValidator: validateDecisiveDate
                        }
                    }
                };
            }

            setSavedContactItems(storage, businessPartner?.Contacts);
            setBusinessPartnerGroupStatus(storage, isNewPartnerWithData(storage));

            if (!entity.Items || entity.Items.length === 0) {
                addNewLineItem(storage, "Items");

                if (this.hasAccountAssignment) {
                    // we need to set up SelectionCode which is not directly doable from defaultValue hence this special code for item
                    // as default value only select value in select
                    // WANTED CHANGE ->  prepareData should check not selection code but select value (as will be in VAT)
                    if (!entity.Items[0].AccountAssignmentSelection) {
                        entity.Items[0].AccountAssignmentSelection = {};
                    }

                    entity.Items[0].AccountAssignmentSelection.Selection = {
                        Code: SelectionCode.Default
                    };
                }

                entity.Items[0].LabelSelection = {
                    Selection: {
                        Code: SelectionCode.Default
                    },
                    SelectionCode: SelectionCode.Default
                };
                setDefaultLabelsFromEntityToItems(storage, false);

                entity.Items[0][SAVED_VATS_PATH] = SelectionCode.Default;
            }

            // For documents created from ISDOC, some fields might be missing -> calculate/default them if possible
            if (!isObjectEmpty(entity.BusinessPartner) && !entity.BusinessPartner.Country) {
                const countryBc = storage.data.bindingContext.navigate(`${DocumentEntity.BusinessPartner}/${DocumentBusinessPartnerEntity.Country}`);
                storage.setDefaultValue(countryBc);
            }
            if (!entity.BankAccount?.IBAN && entity.BankAccount?.AccountNumber) {
                const accountNumberBc = storage.data.bindingContext.navigate(`${DocumentEntity.BankAccount}/${BankAccountEntity.AccountNumber}`);
                tryToCreateIBAN(storage, accountNumberBc);
            }
            if (!entity.BankAccount?.SWIFT && entity.BankAccount?.BankCode) {
                await tryToCreateSwift(storage);
            }

            // TODO: temp hotfix - For documents created from ISDOC, some fields might be missing -> calculate/default them if possible
            if (this.isCashBasisAccountingCompany) {
                setCbaDefaultValuesForNotVisibleFields(this.props.storage);
            }

            if (hasVatClassifications) {
                promises.push(correctVatIds(storage));
            }

            if (this.hasAccountAssignment) {
                setCorrectSpecialItemsForAccountAssignment(storage);
                promises.push(correctAccountIds(storage));
            }

            if (this.isCashBasisAccountingCompany) {
                promises.push(setCorrectSpecialItemsForItemCategory(storage));
            }

            this.validateVatSubmissionsDates();

            if (!this.isSavedWithoutChanges() || hasPreloadedData) {
                promises.push(this.loadRelatedProformaFromDocumentLinks());

                // if TransactionCurrency isn't CZE and ExchangeRatePerUnit is missing, we need to fetch it
                // can happen E.G. when draft is created by BE from ROSSUM
                const origDraftEntity = storage.getCustomData().draft;

                if (origDraftEntity && !origDraftEntity.ExchangeRatePerUnit && storage.data.entity.TransactionCurrency.Code !== getCompanyCurrency(storage.context)) {
                    promises.push(this.refreshCurrencyExchangeRateAfterDateChange(true));
                }
            }

            // local context (CREATE_RECEIPT_PATH switch) is not stored in draft
            // => set CREATE_RECEIPT_PATH to true when CashBox navigation exists
            if (!isObjectEmpty(this.entity.CashBox) && this.props.permissionContext.companyPermissions.has(CompanyPermissionCode.CashBox)) {
                this.props.storage.setValueByPath(CREATE_RECEIPT_PATH, true);
            }

            await Promise.all(promises);

            setMatchingFiscalPeriod(storage, storage.getValueByPath(this.datePropForNumberRange));

            if (isNew) {
                // inject current company to new invoice, we need to do it immediately, because user may leave document
                // form in a way that current company is changed and further request to draft save won't get it from
                // context. todo: should we postpone route change after request for saving draft is resolved?
                entity.Company = { Id: this.getAppContext().getCompanyId() };
                if (hasVatClassifications) {
                    await correctVatSelectionFields({
                        storage,
                        documentTypeCode: this.documentTypeCode
                    });
                    if (hasPreloadedData) {
                        // preloaded data, e.g. from Proforma -> recalculate items to match correctedVatSelectionFields
                        this.recalculateAllItems(DocumentItemEntity.TransactionAmount, true, true);
                    }
                }
            }

            if (!entity.Labels) {
                // in document def Labels keyPath is defined as "Label/Id", unfortunately if Labels are not defined,
                // in Model value of that field is automatically set to { Id: null } which is not iterable array and
                // it causes a lot of exceptions (current version of setting default value results in { Id: [] }
                entity.Labels = [];
            }

            if (!entity.NumberOurs) {
                // Generates NumberOurs for new entities according to current Date/FiscalYear/Period and DocumentType
                // Note: needs to be called after FY and FP is set for wildcard replacement
                await this.setMatchingNumberRangeForDocument(true);
            }

            if (isDraft && entity.NumberRange?.NextNumber) {
                const numberOurs = replaceWildcards(entity.NumberRange.NextNumber, entity, { decisiveDateProp: this.datePropForNumberRange });
                entity.NumberOurs = numberOurs;
                if (!entity.SymbolVariable) {
                    setVariableSymbolFromNumberOurs(this.props.storage, numberOurs, entity.NumberOurs);
                }
            }

            if (isCashBasisAccountingCompany(this.context) && this.documentTypeCode === DocumentTypeCode.InternalDocument) {
                loadCategoriesMap(this.props.storage);
            }

            storage.refresh();
        } else {
            if (this.hasAccountAssignment) {
                setCorrectSpecialItemsForAccountAssignment(storage);
            }
            if (this.isCashBasisAccountingCompany) {
                promises.push(setCorrectSpecialItemsForItemCategory(storage));
            }
            if (hasVatClassifications) {
                promises.push(correctVatIds(storage));
            }

            await Promise.all(promises);
        }
        domManipulatorContext.executeAndDispose();
        return super.onAfterLoad();
    }

    /**
     * Validates one field if bc is provided, otherwise validates all fields
     * @param forDate
     * @param bc items BindingContext
     */
    validateVatFields = (forDate?: Date, bc?: BindingContext): void => {
        const { storage } = this.props;
        const datePropForVat = this.getVatItemsDecisiveDateName();

        if (!storage.isValidPath(datePropForVat)) {
            return null;
        }

        if (!forDate) {
            forDate = storage.getValueByPath(datePropForVat);
        }

        const message = storage.t("Document:Form.WrongVat");
        const itemsBc = storage.data.bindingContext.navigate("Items");
        const correspondingVats = getValidVats(storage, this.documentTypeCode, forDate);

        const itemsToCheck = bc ? [storage.getValue(bc)] : storage.data.entity.Items;

        for (const item of itemsToCheck) {
            const vatBc = itemsBc.addKey(item).navigate("Vat");
            const info = storage.getInfo(vatBc);
            // invalidate items to reload them
            if (info.fieldSettings?.items) {
                info.fieldSettings.items = null;
            }
            storage.addActiveField(vatBc);

            const vatRate = item.Vat?.Rate;
            if (vatRate) {
                const replacementVatRate = correspondingVats.find((vat: TRecordAny) => {
                    return vat.Rate === vatRate;
                });

                if (replacementVatRate) {
                    item.Vat.Code = replacementVatRate.Code;
                    storage.clearError(vatBc);
                } else {
                    storage.setError(vatBc, {
                        message, errorType: ValidationErrorType.Field
                    });
                }
            }
        }

        storage.refreshFields(false, true);
    };

    async refreshFormAfterDecisiveDateChange(path: string, withoutBusy = false): Promise<boolean> {
        const decisiveDate = this.props.storage.getValueByPath(this.datePropForNumberRange);
        const fiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(this.props.storage, decisiveDate);
        const hasChanged = this.props.storage.getValueByPath("FiscalYear")?.Id !== fiscalData.fiscalYear?.Id;
        const { storage } = this.props;

        // Period may change even if FY hasn't, keep it out of the if
        // Period has to be set correctly before setMatchingNumberRangeForDocument method, as it might be used by wildcard
        storage.setValueByPath("FiscalPeriod", fiscalData.fiscalPeriod ?? {});

        if (hasChanged) {
            // Store FY before related data load is triggered as it uses current FY
            storage.setValueByPath("FiscalYear", fiscalData.fiscalYear ?? {});

            // we are showing loader during dateAccountingTransaction change -> this seems that
            // we don't need to do it if fiscalYear hasn't changed
            const promises = [];
            promises.push(refreshAccountIdsForActualFiscalYear(storage, fiscalData.fiscalYear, withoutBusy));

            if (this.datePropForNumberRange === path) {
                promises.push(this.setMatchingNumberRangeForDocument());
            }
            await Promise.all(promises);
            if (!withoutBusy) {
                this.props.storage.refresh();
            }
        }
        return hasChanged;
    }

    async setMatchingNumberRangeForDocument(forceReload?: boolean): Promise<void> {
        if (this.props.preventSettingMatchingNumberRange) {
            return;
        }
        const { storage } = this.props;
        const { entity } = storage.data;
        const origNumberOurs = entity?.NumberOurs || "";
        const hasChanged = await setMatchingNumberRange(storage, forceReload, this.datePropForNumberRange);
        if (!hasChanged) {
            // NumberRange might not been changes, but FY or Period may did, so we need to replace at least wildcards
            replaceWildcardsAfterDateChange(this.props.storage, this.datePropForNumberRange);
        }
        setVariableSymbolFromNumberOurs(this.props.storage, origNumberOurs, entity.NumberOurs);
    }

    handleCurrencyChange = async (e: ISmartFieldChange): Promise<void> => {
        const { storage } = this.props;
        const isCurrencyChange = e.bindingContext.getPath() === "TransactionCurrency";
        if (isCurrencyChange) {
            await refreshExchangeRate(storage, e.value as string, this.datePropForCurrencyExchangeRate);

            if (this.entity[CREATE_RECEIPT_PATH]) {
                storage.setDefaultValueByPath("CashBox");
                storage.refreshFields();
            }

            // invalidate item templates cache
            invalidateItemTemplateSelectItems(storage);
        }
        if (e.bindingContext.getPath() === DocumentEntity.ExchangeRatePerUnit || isCurrencyChange) {
            storage.refreshGroupByKey("Items");
        }
    };

    handleNumberOursChange = (args: ISmartFieldChange): void => {
        if (args.bindingContext.getPath() === DocumentEntity.NumberOurs) {
            const { entity } = this.props.storage.data;
            setVariableSymbolFromNumberOurs(this.props.storage, entity.NumberOurs, args.value as string);
        }
    };

    refreshCurrencyExchangeRateAfterDateChange = async (withoutConfirmation?: boolean): Promise<void> => {
        const { storage } = this.props;
        if (storage.data.entity.TransactionCurrency.Code !== getCompanyCurrency(storage.context)) {
            const exchangeRateBc = this.props.storage.data.bindingContext.navigate(DocumentEntity.ExchangeRatePerUnit);
            const isExchangeRateDisabled = isFieldDisabled(this.props.storage.getInfo(exchangeRateBc), this.props.storage, exchangeRateBc);

            if (!isExchangeRateDisabled) {
                const rate = await getExchangeRate(storage, this.datePropForCurrencyExchangeRate);

                if (rate && rate !== storage.getValueByPath(DocumentEntity.ExchangeRatePerUnit)) {
                    const confirmationPromise = !withoutConfirmation ? this.props.confirmationDialog.open({
                        content: storage.t("Document:Form.RefreshExchangeRate")
                    }) : Promise.resolve(true);
                    const shouldChange = await confirmationPromise;
                    if (shouldChange) {
                        storage.clearAndSetValueByPath(DocumentEntity.ExchangeRatePerUnit, rate);
                    }
                }
            }
        }
    };

    handleDecisiveDateChange = async (e: ISmartFieldChange): Promise<void> => {
        const path = e.bindingContext.getPath();
        const { storage } = this.props;
        if (path === this.datePropForNumberRange && e.triggerAdditionalTasks) {
            const isValid = !await storage.validateField(e.bindingContext);
            if (!isValid) {
                return;
            }

            const { entity } = storage.data;
            const origNumberOurs = entity?.NumberOurs || "";

            const didChangeFY = await this.refreshFormAfterDecisiveDateChange(path);

            if (!didChangeFY && this.datePropForNumberRange === path) {
                replaceWildcardsAfterDateChange(storage, this.datePropForNumberRange);
                setVariableSymbolFromNumberOurs(storage, origNumberOurs, entity.NumberOurs);
            }
            storage.refreshFields();
        }

        if (path === this.datePropForCurrencyExchangeRate && e.triggerAdditionalTasks) {
            const isValid = !await storage.validateField(e.bindingContext);

            if (!isValid || !e.value) {
                return;
            }

            await this.refreshCurrencyExchangeRateAfterDateChange();
            storage.refreshFields();
        }
    };

    // https://solitea-cz.atlassian.net/wiki/spaces/IRIS/pages/2314764398/F0023.1+-+pravy+datumov+ch+field
    // set the FIRST row of the dates to the same value as DateIssued
    handleDateIssuedChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() !== DocumentDraftEntity.DateIssued || !e.triggerAdditionalTasks) {
            return;
        }
        const { storage } = this.props;
        const fieldsToSet = DocumentDateGroupRowsAndOrder[0].filter(id => id !== DocumentDraftEntity.DateIssued) as string[];

        for (const field of fieldsToSet) {
            if (storage.isValidPath(field)) {
                const bindingContext = storage.data.bindingContext.navigate(field);
                const info = storage.getInfo(bindingContext);
                if (isVisible({
                    storage,
                    bindingContext,
                    info,
                    context: storage.context
                }) && !isFieldDisabled(info, storage, bindingContext)) {
                    // DateDue should be +14 in the future or the value set in BP defaults
                    const defaultBpValues = this.isReceivedDoc() ? this.entity?.BusinessPartner?.BusinessPartner?.ReceivedDocumentDefault : this.entity?.BusinessPartner?.BusinessPartner?.IssuedDocumentDefault;
                    const defaultDueDaysValue = defaultBpValues?.DaysDue ?? DefaultDueDays;

                    this.handleChange({
                        ...e,
                        bindingContext: this.props.storage.data.bindingContext.navigate(field),

                        value: field !== DocumentDraftEntity.DateDue ? e.value : getUtcDayjs(e.value as Date).add(defaultDueDaysValue, "day").toDate()
                    });
                }
            }
        }
    };

    // https://solitea-cz.atlassian.net/wiki/spaces/IRIS/pages/2314764398/F0023.1+-+pravy+datumov+ch+field
    // set the SECOND row of the dates to the same value as DateIssued
    handleDateReceivedChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() !== DocumentDraftEntity.DateReceived || !e.triggerAdditionalTasks) {
            return;
        }
        const { storage } = this.props;
        const fieldsToSet = DocumentDateGroupRowsAndOrder[1].filter(id => id !== DocumentDraftEntity.DateReceived) as string[];

        for (const field of fieldsToSet) {
            if (storage.isValidPath(field)) {
                const bindingContext = storage.data.bindingContext.navigate(field);
                const info = storage.getInfo(bindingContext);
                if (isVisible({
                    storage,
                    bindingContext,
                    info,
                    context: storage.context
                }) && !isFieldDisabled(info, storage, bindingContext)) {
                    this.handleChange({
                        ...e,
                        bindingContext: this.props.storage.data.bindingContext.navigate(field)
                    });
                }
            }
        }
    };

    get vatSubmissionDateProperty(): string {
        return this.isReceivedDoc() ? InvoiceReceivedEntity.DateVatDeduction : InvoiceIssuedEntity.DateTaxableSupply;
    }

    validateVatSubmissionsDates = async (savedVats?: TSelectItemId): Promise<void> => {
        const dateProperty = this.vatSubmissionDateProperty;

        if (!this.props.storage.data.bindingContext.isValidNavigation(dateProperty)) {
            return;
        }

        const savedVatsValue = savedVats ?? this.props.storage.getValueByPath(SAVED_VATS_PATH) as TSelectItemId;
        const bc = this.props.storage.data.bindingContext.navigate(dateProperty);

        if (savedVatsValue === SelectionCode.None) {
            this.props.storage.clearAdditionalFieldData(bc);
            this.props.storage.refreshField(bc.getPath());
            return;
        }

        const dateValue = this.props.storage.getValue(bc) as Date;

        if (!dateValue || !DateType.isValid(dateValue)) {
            return;
        }

        const originalDateValue = this.props.storage.getValue(bc, { dataStore: this.props.storage.data.origEntity }) as Date;
        const originalSavedVatsValue = this.props.storage.getValue(this.props.storage.data.bindingContext.navigate(SAVED_VATS_PATH), { dataStore: this.props.storage.data.origEntity }) as TSelectItemId;

        // only validate for new forms or if some of the values has changed
        if (
            !this.props.storage.data.bindingContext.isNew()
            && DateType.isSame(dateValue, originalDateValue)
            && savedVatsValue === originalSavedVatsValue
        ) {
            return;
        }

        let validationResult: IValidationResult = null;
        let isSomeVatSubmitted = false;
        const formattedDate = formatDateToDateString(dateValue);

        try {
            // fetch all existing electronic submissions and check if DateTaxableSupply is in any of those periods
            const submissions = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions)
                .query()
                .filter(`${ElectronicSubmissionEntity.DatePeriodStart} le ${formattedDate} AND ${ElectronicSubmissionEntity.DatePeriodEnd} ge ${formattedDate}`)
                .fetchData<IElectronicSubmissionEntity[]>();

            if (submissions.value.length > 0) {
                isSomeVatSubmitted = true;
            }
        } catch (e) {
            // silent error, this runs async in background and is not that important..
        }

        if (isSomeVatSubmitted) {
            validationResult = {
                status: Status.Warning,
                message: this.props.storage.t("Document:Form.VatSubmittedWarning")
            };

        }

        this.props.storage.setAdditionalFieldData(bc, "validationResult", validationResult);
        this.props.storage.refreshField(bc.getPath());
    };

    handleDateTaxableSupplyChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === InvoiceIssuedEntity.DateTaxableSupply && e.triggerAdditionalTasks) {
            this.validateVatSubmissionsDates();
        }
    };

    handleDateVatDeductionChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === InvoiceIssuedEntity.DateVatDeduction && e.triggerAdditionalTasks) {
            this.validateVatSubmissionsDates();
        }
    };

    getVatItemsDecisiveDateName = memoizeOne(() => {
        return getVatItemsDecisiveDateName(this.props.storage);
    });

    handleVatItemsDecisiveDateChange(e: ISmartFieldChange): void {
        const decisiveDateName = this.getVatItemsDecisiveDateName();

        if (e.bindingContext.getPath() === decisiveDateName && e.triggerAdditionalTasks) {
            const newDate = e.value as Date;
            const origDate = this.props.storage.getValueByPath(decisiveDateName);

            // if this simplification won't work in the future, change it may be to compare list of available vats for the dates??
            const yearHasChanged = getUtcDayjs(newDate).format("YYYY") !== getUtcDayjs(origDate).format("YYYY");

            if (DateType.isValid(newDate) && yearHasChanged) {
                this.validateVatFields(newDate);
            }
        }
    }

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        const error = await this.props.storage.handleBlur(args);

        if (args.wasChanged) {
            await handleBusinessPartnerBlur({ storage: this.props.storage, type: this.documentTypeCode }, args);
            handleBankAccountOrIBANBlur(getBankBlurArgs(this.props.storage, args.bindingContext));

            if (!error) {
                // don't update values if last changed caused validation error
                await this.updateLineItem(args.bindingContext);
            } else {
                // refresh items summaries
                for (const path of ItemsSummariesPaths) {
                    this.props.storage.addActiveField(this.props.storage.data.bindingContext.navigate(path));
                }
            }
        }
        this.documentSpecificBlurHandlers(args);
        this.props.storage.refreshFields();
    };

    /** Checks if any of the lineItemsRecalculateCheckProps is changed for given item */
    isItemAnyRecalcPropSet = (itemBc: BindingContext): boolean => {
        return lineItemsRecalculateCheckProps.some(prop => {
            return isDefined(this.props.storage.getValue(itemBc.navigate(prop)));
        });
    };

    updateLineItem = async (bindingContext: BindingContext, force = false): Promise<void> => {
        const itemsPath = `${this.props.storage.data.bindingContext.getPath(true)}/Items`;
        const triggered = anyPathEquals(bindingContext, lineItemsTriggerProps, itemsPath)
            || force;

        if (!triggered) {
            return;
        }

        const originalItemValue = this.props.storage.getValue(bindingContext.getParent());
        // TODO how many decimalPlaces (MinorUnit) when no currency selected
        const decimalPlaces = this.props.storage.data.entity.Currency?.MinorUnit;
        const newItemValue: TRecordAny = this.recalculateLineItem(originalItemValue, bindingContext.getPath(true), decimalPlaces);

        if (newItemValue) {
            const itemsBc = bindingContext.getParent();

            this.props.storage.setValue(bindingContext.getParent(), newItemValue);

            // TODO only refresh affected fields
            this.props.storage.refresh();

            const lineItemsForceValidateProps = [...lineItemsForceValidatePropsDocItem];

            if (this.isRegularDocumentItem) {
                lineItemsForceValidateProps.push(...lineItemsForceValidatePropsRegularDocItem);
            }

            // force recalculated fields to lose error state
            if (lineItemsForceValidateProps.includes(bindingContext.getPath())) {
                await this.props.storage.validateFields(lineItemsForceValidateProps.map(prop => itemsBc.navigate(prop)));
            }
        }
    };

    handleCustomLineItemAction = async (args: ISmartFastEntriesActionEvent) => {
        // prepared to be overridden
    };

    handleLineItemsAction = async (args: ISmartFastEntriesActionEvent): Promise<void> => {
        const { storage } = this.props;
        const shouldInterrupt = await this.props.onLineItemsAction?.(args, storage);
        if (args.actionType === ActionType.Custom) {
            await this.handleCustomLineItemAction(args);
            return;
        }
        if (shouldInterrupt) {
            return;
        }

        if (args.actionType === ActionType.Add) {
            if (this.hasAccountAssignment) {
                setNestedValue(SelectionCode.Default, "AccountAssignmentSelection/Selection/Code", args.items[args.items.length - 1]);
            }
            setNestedValue(SelectionCode.Default, "LabelSelection/Selection/Code", args.items[args.items.length - 1]);
            setNestedValue(SelectionCode.Default, "LabelSelection/SelectionCode", args.items[args.items.length - 1]);
            // setNestedValue(SelectionCode.Default, SAVED_VATS_PATH, args.items[args.items.length - 1]);
            setNestedValue(SelectionCode.Default, ITEMS_VAT_DEDUCTION_PATH, args.items[args.items.length - 1]);
        }

        if (args.actionType === ActionType.Clone) {
            const [item] = args.affectedItems;

            if (item && !item?.LabelSelection?.Selection) {
                // if for some reason, copied item is missing required LabelSelection/Selection, set it to default
                setNestedValue(SelectionCode.Default, "LabelSelection/Selection/Code", item);
                setNestedValue(SelectionCode.Default, "LabelSelection/SelectionCode", item);
            }

            if (this.hasAccountAssignment && item?.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Copy) {
                processAccountAssignmentSelection(item, true, storage.data.bindingContext.isNew());

                // For new items, ChartOfAccountId is mandatory
                const coaId = getCurrentCoAId({
                    storage,
                    context: this.getAppContext()
                });

                setNestedValue(coaId, "AccountAssignmentSelection/AccountAssignment/ChartOfAccounts/Id", item);
            }
            delete item[DRAFT_ITEM_ID_PATH];
            this.updateLineItem(args.bindingContext);
        }

        args.actionType !== ActionType.Add && this.saveDraft();
        storage.handleLineItemsAction(args);
        setDefaultLabelsFromEntityToItems(this.props.storage);
    };

    getCurrentLineItem = (bc: BindingContext) => {
        const items = this.props.storage.data.entity.Items;
        return getBoundValue({
            bindingContext: bc,
            dataBindingContext: this.props.storage.data.bindingContext,
            data: items
        });
    };

    handleItemsVatDeductionChange({ bindingContext, value, triggerAdditionalTasks }: ISmartFieldChange): boolean {
        const { storage } = this.props;

        if (bindingContext.getPath() === ITEMS_VAT_DEDUCTION_PATH && triggerAdditionalTasks) {
            const currentValue = storage.getValue(bindingContext);

            // set Own selection to temporal data
            const itemBc = bindingContext.getParent();
            const itemVatSelectionBc = itemBc.navigate(DocumentItemEntity.VatClassificationSelection);
            const itemVatClassificationBc = itemVatSelectionBc.navigate(DocumentVatClassificationSelectionEntity.VatClassification);
            const itemVatSelectionCodeBc = itemVatSelectionBc.navigate("Selection/Code");

            const isVatDeductionWithAdditionalData = isProportionalDeduction(value);
            // For proportional deduction, we need to set up additional data (coefficient + NonTaxAccount)
            // -> just display dialog for it
            if (isVatDeductionWithAdditionalData) {
                storage.setCustomData({
                    dialogCurrentLineItem: this.getCurrentLineItem(bindingContext.getParent()),
                    isVatDialogOpened: true
                });

                storage.setTemporalData(bindingContext, { value });
                storage.setTemporalData(itemVatClassificationBc.navigate(DocumentVatClassificationEntity.VatDeductionType), { value });
                const proportionalDeductionBc = itemVatClassificationBc.navigate(DocumentVatClassificationEntity.VatProportionalDeduction);
                const proportionalDeduction = storage.getValue(proportionalDeductionBc) ?? storage.getDefaultValue(proportionalDeductionBc);
                storage.setTemporalData(proportionalDeductionBc, { value: proportionalDeduction });
                storage.setTemporalData(itemVatSelectionCodeBc, { value: SelectionCode.Own });

                if (currentValue !== value) {
                    // clear fields as new value has been picked
                    clearVatClassificationFields(storage, itemBc, true);
                }

                storage.refresh();
                // stops processing the change -> just dialog is opened
                return true;
            } else {
                // set vat classification to custom or Default value of VatDeduction.
                // Other fields will be copied from common rule.
                clearVatClassificationFields(storage, itemBc);
                storage.setValue(bindingContext, value);
                storage.setValue(itemVatSelectionCodeBc, value !== SelectionCode.Default ? SelectionCode.Own : value);
                if (value !== SelectionCode.Default) {
                    storage.setValue(itemVatClassificationBc.navigate(DocumentVatClassificationEntity.VatDeductionType), value);
                }
            }
        }

        return false;
    }

    applyItemDeductionType = (): Promise<boolean> => {
        const { storage } = this.props;
        const { dialogCurrentLineItem } = storage.getCustomData();
        const itemBc = storage.data.bindingContext.navigate("Items").addKey(dialogCurrentLineItem);
        const itemVatSelectionBc = itemBc.navigate(DocumentItemEntity.VatClassificationSelection);
        const itemVatClassificationBc = itemVatSelectionBc.navigate(DocumentVatClassificationSelectionEntity.VatClassification);
        const itemVatSelectionCodeBc = itemVatSelectionBc.navigate("Selection/Code");

        return storage.confirmFields([
            itemVatClassificationBc.navigate(DocumentVatClassificationEntity.VatDeductionType),
            itemVatSelectionCodeBc
        ]);
    };

    handleLineItemsChange(args: ISmartFieldChange): void {
        const { storage } = this.props;

        if (handleItemAccAssignmentDialog({ storage, e: args })
            || this.handleItemsVatDeductionChange(args)) {
            // Account assignment / Vat classification dialog is shown
            // -> do not process the change further as the dialog might be canceled
            // -> we don't want to keep "Selection.Own" in the select then.
            return;
        }
        const path = args.bindingContext.getPath(true);

        // call before storage update to be able to work with old value
        // this.handleSavedVatsChange(args);
        this.handleItemProportionalDeductionChange(args);

        storage.handleLineItemsChange(args);

        if (args.triggerAdditionalTasks) {
            let forceUpdateItem = false;
            if (path === DocumentItemEntity.Description) {
                forceUpdateItem = handleItemTemplateChange(args, storage);
                this.validateVatFields();
            }
            this.handleItemVatChange(args);
            this.handleItemLabelsChange(args);
            handleItemCbaCategoryChange(args, storage);
            this.defaultVatRuleAccordingToCbaCategory(args);
            this.handleItemAccAssignment(args);
            this.updateLineItem(args.bindingContext, forceUpdateItem);
        }

        this.saveDraft();
        storage.refreshFields();
    }

    handleItemAccAssignment = (e: ISmartFieldChange): void => {
        if (e.bindingContext?.getPath() === "AccountAssignment") {
            handleItemAccAssignmentChange({
                storage: this.props.storage,
                e
            });

            const itemBc = e.bindingContext.getParent().getParent();
            const item = this.props.storage.getValue(itemBc);
            this.updateItemVat(item, itemBc);
            this.forceUpdate();
        }
    };

    handleNumberRange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === "NumberRange") {
            this.props.storage.data.entity.NumberRange = e.value ? e.additionalData : null;
        }
    };

    handleBusinessPartnerChange = async (e: ISmartFieldChange): Promise<void> => {
        const shouldTriggerChange = e.triggerAdditionalTasks && isBusinessPartnerField(e.bindingContext.getNavigationPath(true));
        const storage = this.props.storage;

        if (shouldTriggerChange) {
            const { IssuedDocumentDefault, ReceivedDocumentDefault } = e.additionalData ?? {};
            const bpPath = e.bindingContext.getParent().navigate(DocumentBusinessPartnerEntity.BusinessPartner);
            storage.setValue(bpPath.navigate(BusinessPartnerEntity.ReceivedDocumentDefault), ReceivedDocumentDefault);
            storage.setValue(bpPath.navigate(BusinessPartnerEntity.IssuedDocumentDefault), IssuedDocumentDefault);

            await setDefaultsFromBusinessPartner({
                storage,
                type: this.documentTypeCode,
                businessPartner: e.additionalData
            }, this.refreshDateDue);
        }

        // business partner change can change TransactionCurrency => we need to update the CashBox as well
        const originalCurrency = this.entity.TransactionCurrency?.Code;

        // call after changes to entity as this method requires entity to be updated
        await handleBusinessPartnerChange({
            storage,
            type: this.documentTypeCode
        }, e, { currencyDatePropPath: this.datePropForCurrencyExchangeRate });

        if (shouldTriggerChange && originalCurrency !== this.entity.TransactionCurrency?.Code) {
            storage.setDefaultValueByPath("CashBox");
        }

        this.refreshBankGroup();
    };

    updateItemsVat = (): void => {
        for (const item of this.props.storage.data.bindingContext.iterateNavigation("Items", this.props.storage.data.entity.Items)) {
            this.updateItemVat(item.entity, item.bindingContext);
        }
    };

    /** We want to preselect null Vat for an item when item uses default accountAssignmentSelection */
    updateItemVat = (item: IEntity, bindingContext: BindingContext): void => {
        if (!this.shouldUpdateItemVat(bindingContext)) {
            return;
        }

        const previousVat = item?.Vat;
        const vatBc = bindingContext.navigate("Vat");
        const vat = !previousVat?.Rate ? this.getVatItem(vatBc, VatCode.CzStd2013) : item?.Vat;

        this.props.storage.clearAndSetValue(vatBc, vat);

        if ((!isObjectEmpty(vat) || !isObjectEmpty(previousVat)) && (vat !== previousVat)) {
            this.updateLineItem(vatBc);
        }
    };

    shouldUpdateItemVat = (bindingContext: BindingContext): boolean => {
        // we want the default first item to have correct preselected Vat, no automatic changes to Vat after that should be done
        // => only update FIRST item, if not dirty and if no other items are present
        // https://solitea-cz.atlassian.net/browse/DEV-3225
        return bindingContext.isNew() && bindingContext.getKey() === 1 && !this.props.storage.isDirty(bindingContext) && this.props.storage.data.entity.Items?.length === 1;
    };

    getVatItem = (bindingContext: BindingContext, vatCode: VatCode) => {
        const vatItem = this.props.storage.getInfo(bindingContext)?.fieldSettings?.items?.find(item => item.id === vatCode)?.additionalData;

        if (!vatItem) {
            logger.error(`DocumentFormView: trying to set vat code that doesn't exist in the loaded vat items: ${vatCode}`);
        }

        return vatItem ?? {};
    };

    documentSpecificChangeHandlers(e: ISmartFieldChange): void {
        // prepared to be overridden
    }

    documentSpecificBlurHandlers(e: ISmartFieldBlur): void {
        // prepared to be overridden
    }

    refreshBankGroup = (): void => {
        this.props.storage.addActiveGroupByKey("payment");
    };

    handleItemVatChange = (args: ISmartFieldChange): void => {
        if (args.bindingContext.getPath() === DocumentItemEntity.Vat) {
            if (!args.value) {
                this.props.storage.clearError(args.bindingContext);
            }
            const forDate = this.props.storage.getValueByPath(this.getVatItemsDecisiveDateName());
            this.validateVatFields(forDate, args.bindingContext.getParent());
        }
    };

    handleItemLabelsChange = (args: ISmartFieldChange): void => {
        if (args.bindingContext.getNavigationPath(true) === "Items/LabelSelection/Labels") {
            const { storage } = this.props;
            const labelSelectionBc = args.bindingContext.getParentCollection().navigate(DocumentItemEntity.LabelSelection);
            const value = args.value as (string | number)[];
            const isDefault = value?.includes(SelectionCode.Default);
            if (isDefault) {
                storage.setValue(labelSelectionBc, {
                    Selection: {
                        Code: SelectionCode.Default
                    },
                    SelectionCode: SelectionCode.Default,
                    Labels: [...this.entity.Labels]
                });
            } else if (args.additionalData?.isNoRecord || value?.length === 0) {
                storage.setValue(labelSelectionBc, {
                    Selection: {
                        Code: SelectionCode.None
                    },
                    SelectionCode: SelectionCode.None,
                    Labels: []
                });
            } else {
                storage.setValue(labelSelectionBc.navigate("SelectionCode"), SelectionCode.Own);
                storage.setValue(labelSelectionBc.navigate("Selection"), { Code: SelectionCode.Own });
            }
        }
    };

    handleSavedVatsChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === SAVED_VATS_PATH && e.triggerAdditionalTasks) {
            const { storage } = this.props;
            const isItem = e.bindingContext.getFullPath().includes("/Items");

            if (!isItem && (e.value === SelectionCode.Own || !e.value)) {
                storage.expandGroup(!!e.value, getCollapsedGroupId({ id: SAVED_VATS_PATH }));
            }

            const selectionCode = correctSelectionCode(e.value);
            const vatClassificationBc = e.bindingContext.getParent().navigate("VatClassificationSelection");

            const parentBc = e.bindingContext.getParent();
            if (!selectionCode) {
                // clear all possible errors in subfields
                clearVatClassificationFields(storage, parentBc);
                // remove the entity
                storage.clearAndSetValue(vatClassificationBc, null);
            } else {
                if ([SelectionCode.None, SelectionCode.Default].includes(selectionCode)) {
                    clearVatClassificationFields(storage, parentBc);
                } else if (e.value === SelectionCode.Own) {
                    const previousValue = storage.getValue(e.bindingContext);
                    const previousCode = correctSelectionCode(previousValue);
                    if (previousCode !== SelectionCode.Own) {
                        clearVatClassificationFields(storage, parentBc, true);
                    }
                } else {
                    // SelectionCode.Copy
                    storage.processDependentField(getVatDependentFields(), e.additionalData, e.bindingContext);
                    // process "copy" account number -> there is only account name and number in the additional data.
                    // We need to send correct Id of the particular account from the current FY
                    const nonTaxAccountBc = vatClassificationBc
                        .navigate(DocumentVatClassificationSelectionEntity.VatClassification)
                        .navigate(DocumentVatClassificationEntity.NonTaxAccount);
                    const { NonTaxAccountName, NonTaxAccountNumber } = e.additionalData;
                    await applyAccountFromTemplate(storage, nonTaxAccountBc, {
                        Name: NonTaxAccountName,
                        Number: NonTaxAccountNumber
                    });
                }

                // when selectionCode is defined, always process GetSelectionCodeDependentField to keep it actual in all cases
                storage.processDependentField([getSelectionCodeDependentField()], e.additionalData, e.bindingContext);
            }

            storage.refreshGroupByKey(getVatAssignmentGroupId(this.props.storage));

            this.validateVatSubmissionsDates(e.value as TSelectItemId);
        }
    };

    handleItemProportionalDeductionChange = (e: ISmartFieldChange): void => {

        if (e.bindingContext.getPath() === DocumentVatClassificationEntity.VatProportionalDeduction) {
            const { storage } = this.props;
            const mainBc = e.bindingContext.getParent().getParent().getParent();
            // update selectionCode to own if children changes
            storage.setValue(mainBc.navigate("VatClassificationSelection/Selection/Code"), SelectionCode.Own);
            const vatDeductionBc = mainBc.navigate(ITEMS_VAT_DEDUCTION_PATH);

            /* different behavior on Items, no SavedVats */
            this.applyItemDeductionType()
                .then(() => {
                    correctVatDeduction(storage.getValue(mainBc));
                    return storage.validateField(vatDeductionBc);
                })
                .then(() => {
                    storage.addActiveField(vatDeductionBc);
                    storage.refreshFields(true);
                });
        }
    };

    handleVatChildrenChange = (e: ISmartFieldChange): void => {
        const { storage } = this.props;
        const path = e.bindingContext.getPath();

        if (path === VAT_REVERSE_CHARGE_SELECT_PATH && e.triggerAdditionalTasks) {
            storage.setValue(storage.data.bindingContext.navigate(`${VAT_CLASSIFICATION_PATH}/${VatClassificationEntity.VatReverseCharge}`), e.value);
        }

        if (path === "VatReverseCharge") {
            const value = e.value ? VatReverseChargeCode.Ano : VatReverseChargeCode.Ne;
            storage.clearAndSetValue(e.bindingContext, value);
            storage.correctEnumValue(e.bindingContext, value);
            storage.clearAndSetValueByPath(VAT_REVERSE_CHARGE_SELECT_PATH, null);
        }

        const navigationPath = e.bindingContext.getNavigationPath();

        if (navigationPath.includes("VatClassificationSelection/VatClassification")) {
            const mainBc = e.bindingContext.getParent().getParent().getParent();

            // update selectionCode to own if children changes
            storage.setValue(mainBc.navigate("VatClassificationSelection/Selection/Code"), SelectionCode.Own);
            correctDataAfterVatChildrenChangeOnDocument(this.props.storage);

            // change required stuff refresh the whole group
            this.props.storage.addActiveGroup(e.bindingContext);
        }
    };

    defaultVatRuleAccordingToCbaCategory(args: ISmartFieldChange): void {
        if (args.bindingContext.getPath() === CBA_CATEGORY_PATH && args.triggerAdditionalTasks) {
            // const { storage } = this.props;
            // const { bindingContext: dataBindingContext } = storage.data;
            // const deductionBc = dataBindingContext.navigate(ITEMS_VAT_DEDUCTION_PATH);
            //
            // if (!dataBindingContext.isNew() || storage.isDirty(deductionBc)) {
            //     // we default only value for new documents without user manual change
            //     return;
            // }
            //
            // const category = getCBAItemCategory(storage, args.bindingContext.getParent(), true);
            // const isPartial = category.TaxImpactCode === CbaCategoryTaxImpactCode.Partial;
            // if (category.isDefault) {
            //     storage.setValue(deductionBc, SelectionCode.Default);
            // } else if (isPartial ||
            //     (category.TaxImpactCode === CbaCategoryTaxImpactCode.Nontax && !category.IsAssetAcquisition)) {
            //     // proportional Vat deduction
            //     const percentage = isPartial ? category.TaxPercentage : 0;
            // } else {
            //     //
            // }
        }
    }

    /**
     * Handles change of business partner fields, which is significant to VatClassification rules
     * @param e
     * @param prevOptions
     */
    handleVatMasterFieldsChange = async (e: ISmartFieldChange, prevOptions: IVatConfigOptions): Promise<void> => {
        if (e.triggerAdditionalTasks === false) {
            // temporal change, e.g. in Date field -> do not handle such changes
            return;
        }

        const { storage } = this.props;
        const { bindingContext } = storage.data;
        const path = e.bindingContext.getPath();
        const parentPath = e.bindingContext.getParent().getPath();
        const isVatMasterFieldPath = parentPath === "BusinessPartner" &&
            (path === "Country" || path === "VatStatus" || path === "Name" || path === "LegalNumber");

        const isVatMasterFieldChange = e.triggerAdditionalTasks && isVatMasterFieldPath;
        if (isVatMasterFieldChange) {
            await correctVatSelectionFields({
                storage,
                documentTypeCode: this.documentTypeCode
            });
        }

        const reverseChargePath = "VatClassificationSelection/VatClassification/VatReverseCharge";
        const bc = bindingContext.navigate(reverseChargePath);
        const selection = storage.getValueByPath(SAVED_VATS_PATH);

        const isOwnOrNotSetRule = !selection || [SelectionCode.Own, SelectionCode.None].includes(selection);

        if (isVatMasterFieldChange) {
            if (!storage.isDirty(bc) && isOwnOrNotSetRule) {
                storage.setDefaultValueByPath(reverseChargePath);
            }

            // we need to refresh whole page so that the Vat group is re-rendered
            // this storage.addActiveGroupByKey(getVatAssignmentGroupId(this.props.storage));
            // is not enough when the group is not rendered cause of isVisible callback,
            // - no reference for it exists, whole PureForm has to be re-rendered instead
            storage.refresh();
            if (isReceived(this.documentTypeCode)) {
                storage.addActiveField(bindingContext.navigate("NumberTheirs"));
            }
        }

        let shouldRefreshItems = path === "VatReverseCharge";

        // todo: Change this after DEV-13471 is done
        if (this.documentTypeCode !== DocumentTypeCode.InternalDocument) {
            const currentOptions = getVatConfigOptions(storage, this.documentTypeCode);

            // first, default value for AutomatedVatInclusionMode
            if (isOwnOrNotSetRule && currentOptions.isInAutomatedVatInclusionModeVisible && !prevOptions.isInAutomatedVatInclusionModeVisible) {
                currentOptions.isInAutomatedVatInclusionMode = storage.setDefaultValueByPath(AUTOMATED_VAT_INCLUSION_MODE_PATH) as boolean;
            }

            const reDefaultVat = currentOptions.defaultVatRate !== prevOptions.defaultVatRate && bindingContext.isNew();
            if ((currentOptions.isInAutomatedVatInclusionMode !== prevOptions.isInAutomatedVatInclusionMode)
                || (currentOptions.isReverseCharged !== prevOptions.isReverseCharged)
                || reDefaultVat) {
                // recalculates all items and refreshes item group
                this.recalculateAllItems(this.defaultItemLastChangedProp ?? DocumentItemEntity.TransactionAmountNet, true, reDefaultVat);
                shouldRefreshItems = true;
            }

            if (currentOptions.isInAutomatedVatInclusionMode) {
                storage.setDefaultValueByPath("VatClassificationSelection/VatClassification/VatControlStatementSection");
            }
        }
        if (shouldRefreshItems) {
            // refresh amounts isDisabled
            storage.addActiveGroupByKey("Items");
        }
        if (shouldRefreshItems || isVatMasterFieldChange) {
            // method is async, we need to refresh groups after all calls have finished
            storage.refreshFields(true);
        }
    };

    // according to DEV-22624, we should default "VatSelection.None" when in
    async handleVatDefaulting({ bindingContext, triggerAdditionalTasks }: ISmartFieldChange): Promise<void> {
        const { storage } = this.props;
        const parentPath = bindingContext?.getParent()?.getPath();
        const path = bindingContext?.getPath();
        const vatStatusChangePaths = [DocumentBusinessPartnerEntity.VatStatus, DocumentBusinessPartnerEntity.Name, DocumentBusinessPartnerEntity.LegalNumber] as string[];
        const canDefaultVatClassification = storage.data.bindingContext.isNew();
        if (triggerAdditionalTasks && canDefaultVatClassification &&
            parentPath === DocumentEntity.BusinessPartner && vatStatusChangePaths.includes(path)) {
            await correctVatSelectionFields({
                storage,
                documentTypeCode: this.documentTypeCode,
                forceDefaultValue: true
            });
            storage.addActiveGroupByKey(getVatAssignmentGroupId(this.props.storage));
            storage.refreshFields(true);
        }
    }

    handleLabelsChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === DocumentEntity.Labels && e.triggerAdditionalTasks) {
            setDefaultLabelsFromEntityToItems(this.props.storage);
        }
    };

    handleChange(e: ISmartFieldChange): void {
        const { storage } = this.props;
        const originalVatOptions = getVatConfigOptions(storage, this.documentTypeCode);

        this.handleBusinessPartnerChange(e);

        if (this.hasAccountAssignment) {
            handleAccAssignmentChildrenChange(storage, e);
            handleAccAssignmentChange({
                storage,
                event: e,
                documentTypeCode: this.documentTypeCode
            });
        }
        this.handleSavedVatsChange(e);
        this.handleNumberOursChange(e);
        // call before change, method checks previous value of the date
        this.handleVatItemsDecisiveDateChange(e);

        storage.handleChange(e);

        // must be called after change
        handleCbaCategoryChange({
            storage,
            documentTypeCode: this.documentTypeCode,
            e
        });
        this.handleCurrencyChange(e);
        this.handleVatChildrenChange(e);
        this.handleVatMasterFieldsChange(e, originalVatOptions);
        this.handleLabelsChange(e);

        this.handleVatDefaulting(e);

        if (this.hasAccountAssignment) {
            correctAccountIdAfterChange(storage, e);
        }

        const businessPartner = (storage.data.entity as IDocumentEntity).BusinessPartner?.BusinessPartner;
        const args: IBankAccountArgs = {
            storage,
            businessPartner,
            type: this.documentTypeCode
        };
        handleBankFieldChange(args, e);
        handlePaymentMethodChange(args, e);
        handleCreateReceiptsChange(e, storage);

        this.handleNumberRange(e);

        this.documentSpecificChangeHandlers(e);

        this.handleDecisiveDateChange(e);
        this.handleDateIssuedChange(e);
        this.handleDateReceivedChange(e);
        this.handleDateTaxableSupplyChange(e);
        this.handleDateVatDeductionChange(e);


        // triggerAdditionalTasks === true for select like components has same
        // meaning as triggerAdditionalTasks === undefined for the rest types
        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.saveDraft();
        storage.refreshFields(shouldUpdate);

    }

    getSuccessMessage(isPosted: boolean, isClosed: boolean): string {
        const namespace = this.props.storage.data.definition.translationFiles[0];
        let key: string;
        if (isPosted) {
            key = isClosed ? "SuccessSubtitlePostedClosedFiscalYear" : "SuccessSubtitlePosted";
        } else {
            key = "Saved";
        }
        return this.props.storage.t(`${namespace}:Validation.${key}`).toString();
    }

    handleSaveClick = (): void => {
        this.save({
            successSubtitle: this.getSuccessMessage(false, false)
        });
    };

    handleSaveAndPostClick = (): void => {
        // use different text when saving changes in closed FiscalYear
        const isClosed = this.props.storage.data.entity.FiscalYear?.StatusCode === FiscalYearStatusCode.Closed;

        this.save({
            queryParams: {
                postInvoice: true
            },
            successSubtitle: this.getSuccessMessage(true, isClosed)
        });
    };

    setBreadcrumbs = (): void => {
        if (!this.props.withoutBreadCrumbs) {
            const title = this.props.storage.data.definition.getItemBreadCrumbText(this.props.storage);
            setLockBreadcrumbs({
                storage: this.props.storage,
                title,
                onBeforeSave: this.props.onBeforeSave,
                onSaveFail: this.props.onSaveFail,
                onTableRefreshNeeded: this.props.onTableRefreshNeeded,
                onAfterSave: this.onAfterSave,
                context: this.getAppContext()
            });
        }
    };

    loadRelatedProformaFromDocumentLinks = async (): Promise<void> => {
        const { storage } = this.props;
        const proformaBcs = getDocumentLinkBcs(storage.oData, storage.data.entity.DocumentLinks, DocumentLinkTypeCode.ProformaInvoiceDeduction);
        storage.data.additionalResults[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX] = await loadRelatedProformas(storage.data.bindingContext, storage.oData, proformaBcs);
    };

    prepareVatForSave = (data: IEntity, isItem: boolean): void => {
        const { storage } = this.props;
        const coreData = storage.data.entity as IDocumentEntity;
        const isBasicProforma = [DocumentTypeCode.ProformaInvoiceReceived, DocumentTypeCode.ProformaInvoiceIssued].includes(this.documentTypeCode)
            && !(coreData as IProformaInvoiceReceivedEntity).IsTaxDocument;
        if (isBasicProforma || this.documentTypeCode === DocumentTypeCode.InternalDocument) {
            // according to https://solitea-cz.atlassian.net/browse/DEV-21857
            // VatClassificationSelection is never present on internal documents
            // and of course also on basic proforma documents, which are not tax related
            delete data.VatClassificationSelection;
            return;
        }
        const _isReceivable = isReceived(this.documentTypeCode);

        if (isItem) {
            if (!data.VatClassificationSelection) {
                data.VatClassificationSelection = {};
            }
            const selection = data.VatClassificationSelection.Selection?.Code ?? data.VatClassificationSelection.SelectionCode;
            if (selection !== SelectionCode.Own) {
                data.VatClassificationSelection.SelectionCode = SelectionCode.Default;
                data.VatClassificationSelection.VatClassification = null;
                if (isItem) {
                    // TODO: right now we are validating this.entity and not modified data, this should fixed situation where
                    // original value has empty object for navigation, and has hidden required fields, maybe change validateAndSave
                    // on FormStorage in the future to use modified data
                    const vatClassificationBc = storage.data.bindingContext.navigate("Items").addKey(data)
                        .navigate(RegularDocumentItemEntity.VatClassificationSelection)
                        .navigate(DocumentItemVatClassificationSelectionEntity.VatClassification);
                    storage.setValue(vatClassificationBc, null);
                }
            } else {
                const defaultRule = coreData.VatClassificationSelection?.VatClassification ?? {};
                const { VatClassification } = data.VatClassificationSelection;
                data.VatClassificationSelection.VatClassification = {
                    ...defaultRule,
                    VatDeductionType: VatClassification?.VatDeductionType,
                    VatDeductionTypeCode: VatClassification?.VatDeductionType?.Code,
                    VatProportionalDeduction: VatClassification?.VatProportionalDeduction,
                    NonTaxAccount: VatClassification?.NonTaxAccount
                };
                if (this.hasAccountAssignment) {
                    data.VatClassificationSelection.VatClassification.ChartOfAccounts = VatClassification?.NonTaxAccount ? {
                        Id: getCurrentCoAId({ storage: this.props.storage }, {
                            datePath: "DateAccountingTransaction",
                            fallbackToActiveChOA: false
                        })
                    } : null;
                }

                // todo: remove this @Matej move this to BE validation and remove from DocumentItemVatClassification
                data.VatClassificationSelection.VatClassification.CountryClassificationCode = getClassificationCodeFromCountry(coreData?.BusinessPartner?.Country);
                data.VatClassificationSelection.VatClassification.PayableReceivableTypeCode = /*this.documentTypeCode === DocumentTypeCode.InternalDocument ? PayableReceivableTypeCode.InternalDocument :*/
                    (_isReceivable ? PayableReceivableTypeCode.Payable : PayableReceivableTypeCode.Receivable);
                data.VatClassificationSelection.VatClassification.IsVatRegistered = isVatRegisteredEntity({ storage });
                // todo: remove this ^^^
            }
        } else {
            const savedVat = data[SAVED_VATS_PATH];
            const VatClassificationBc = storage.data.bindingContext.navigate(`${isItem ? "Items/" : ""}VatClassificationSelection/VatClassification`);

            const _appendVatRequiredFieldsForSave = () => {
                const vatSel = data.VatClassificationSelection?.VatClassification ?? {};

                // todo: remove this @Matej move this to BE validation and remove from DocumentVatClassification
                vatSel.CountryClassificationCode = getClassificationCodeFromCountry(coreData?.BusinessPartner?.Country);
                vatSel.PayableReceivableTypeCode = /*this.documentTypeCode === DocumentTypeCode.InternalDocument ? PayableReceivableTypeCode.InternalDocument :*/
                    (_isReceivable ? PayableReceivableTypeCode.Payable : PayableReceivableTypeCode.Receivable);
                vatSel.IsVatRegistered = isVatRegisteredEntity({ storage });
                // todo: remove this ^^^

                const bindingContext = VatClassificationBc.navigate("VatReverseCharge");
                const isReverseChargeVisible = isVisible({
                    info: storage.getInfo(bindingContext),
                    bindingContext,
                    storage,
                    context: storage.context
                });
                if (!isReverseChargeVisible) {
                    vatSel.VatReverseChargeCode = null;
                } else if (!vatSel.VatReverseCharge) {
                    vatSel.VatReverseChargeCode = "No";
                }

                if (this.hasAccountAssignment) {
                    const nonTaxAccountBc = VatClassificationBc.navigate(DocumentVatClassificationEntity.NonTaxAccount);
                    const _isProportional = hasVatProportionalDeduction({ storage, bindingContext: nonTaxAccountBc });
                    vatSel.ChartOfAccounts = _isProportional ? {
                        Id: getCurrentCoAId({ storage }, {
                            datePath: "DateAccountingTransaction",
                            fallbackToActiveChOA: false
                        })
                    } : null;
                } else {
                    delete vatSel.NonTaxAccount;
                    delete vatSel.ChartOfAccounts;
                }

                if (isItem) {
                    vatSel.VatControlStatementSectionCode = this.props.storage.data.entity.VatClassificationSelection?.VatClassification?.VatControlStatementSection?.Code;
                }
            };

            if (!savedVat) {
                data.VatClassificationSelection = null;
            } else {
                if (!data.VatClassificationSelection) {
                    data.VatClassificationSelection = {};
                }
                if ([SelectionCode.None, SelectionCode.Default].includes(savedVat)) {
                    data.VatClassificationSelection.VatClassification = {};
                    data.VatClassificationSelection.SelectionCode = savedVat;
                } else {
                    // Own selection or SelectionCode.Copy (use the filled data)
                    _appendVatRequiredFieldsForSave();
                    data.VatClassificationSelection.SelectionCode = savedVat === SelectionCode.Own ? SelectionCode.Own : SelectionCode.Copy;
                }
                // correct value also in storage
                storage.setValue(VatClassificationBc.getParent().navigate(createPath(VatClassificationSelectionEntity.Selection, SelectionEntity.Code)), data.VatClassificationSelection.SelectionCode);
            }

            if (data?.VatClassificationSelection?.Selection) {
                delete data.VatClassificationSelection.Selection;
            }
        }
    };

    // in some cases, we need to alter the data that are sent to save method
    // because we store DEFINITION of VatAssignments inside the DATA
    prepareOneEntityForSave = (data: IEntity, isItem: boolean): void => {
        if (this.hasAccountAssignment) {
            prepareAccountAssignmentsForSave(this.props.storage, data);
        }
        this.prepareVatForSave(data, isItem);
    };

    // and this definition can't be part of the request
    prepareDataForSave = async (data: IEntity, isDraft?: boolean): Promise<IDocumentExtendedEntity> => {
        const { storage } = this.props;

        // call onBeforeSave - it could be overridden in some extensions of DocumentFormView
        const preparedData = this.onBeforeSave(data) ?? cloneDeep(data);
        const isNew = storage.data.bindingContext.isNew();

        if (isNew) {
            preparedData.Currency = { Code: getCompanyCurrency(storage.context) };
        }

        this.prepareOneEntityForSave(preparedData, false);

        // if there is only country code, delete all bank accounts, so we don't create additional request
        deleteEmptyBankAccount(preparedData);

        if (!isVisibleByPath(storage, SAVED_ACCOUNTS_PATH)) {
            preparedData.BankAccount = null;
        } else if (!isVisibleByPath(storage, "BankAccount/Bank")) {
            preparedData.BankAccount.BankCode = null;
            preparedData.BankAccount.Bank = null;
        }

        if (isDraft) {
            if (isDefined(preparedData.BusinessPartner) && !preparedData.BusinessPartner?.BusinessPartner?.Id) {
                delete preparedData.BusinessPartner.BusinessPartner;
            }
        } else {
            prepareNewBusinessPartner(storage, preparedData, this.isReceivedDoc());
        }

        if (!storage.data.entity.ExchangeRatePerUnit && storage.data.entity.TransactionCurrency.Code === getCompanyCurrency(storage.context)) {
            // if for some wild unknown reason, ExchangeRatePerUnit is missing and currency is Cze,
            // set 1, to make everything work
            preparedData.ExchangeRatePerUnit = 1;
        }

        const rate = preparedData.ExchangeRatePerUnit;

        if (preparedData.Items) {
            preparedData.Items.forEach((item: IRegularDocumentItemEntity) => {
                this.prepareOneEntityForSave(item, true);
                const currencyDependentFields = [...currencyDependentFieldsDocItem];

                if (this.isRegularDocumentItem) {
                    currencyDependentFields.push(...currencyDependentFieldsRegularDocItem);
                }

                const isIDClearingItem = (item as IInternalDocumentItemEntity).LinkedDocument?.Id && this.documentTypeCode === DocumentTypeCode.InternalDocument;
                if (!isIDClearingItem && NumberType.isValid(item.TransactionAmount) && NumberType.isValid(item.TransactionAmountNet) && NumberType.isValid(item.TransactionAmountVat)) {
                    for (const field of currencyDependentFields) {
                        if (!storage.getBackendDisabledFieldMetadata(storage.data.bindingContext.navigate(`Items/${field}`))) {
                            if (rate === 1) {
                                item[field] = item[`Transaction${field}` as TDocumentTransactionProps];
                            } else {
                                item[field] = roundToDecimalPlaces(2, (item[`Transaction${field}` as TDocumentTransactionProps]) * rate);
                            }
                        }
                    }
                    item["Amount"] = new Big(item["AmountNet"]).add(item["AmountVat"] ?? 0).toNumber();
                }
            });
        }

        // FiscalPeriod is only shown in form, isn't part of the post request
        delete preparedData.FiscalPeriod;
        // we don't want to send unnecessary patch for DeferredPlans,
        // DeferredPlans are only loaded to check items with already existing plan, but it is never directly updated in the form
        delete preparedData.DeferredPlans;
        if (isDraft) {
            delete preparedData.DocumentDraft;
        }

        // https://solitea-cz.atlassian.net/browse/DEV-20589
        // DateTaxableSupply is supposed to be hidden in some case, but BE fails if it is empty
        if (storage.data.bindingContext.isValidNavigation("DateTaxableSupply") && !(preparedData as IDocumentDraftEntity)?.DateTaxableSupply) {
            (preparedData as IDocumentDraftEntity).DateTaxableSupply = (preparedData as IDocumentDraftEntity).DateIssued;
        }

        return preparedData;
    };

    onBeforeExecute = (batch: BatchRequest): Promise<void> => {
        // prepared to be overridden
        return null;
    };

    async confirmationBeforeSave(): Promise<boolean> {
        const { storage, confirmationDialog } = this.props;

        if (!this.props.storage.data.bindingContext.isValidNavigation(this.vatSubmissionDateProperty)) {
            return true;
        }

        const dateBc = storage.data.bindingContext.navigate(this.vatSubmissionDateProperty);
        const validationResult = storage.getAdditionalFieldData(dateBc, "validationResult");

        if (validationResult?.status === Status.Warning) {
            return confirmationDialog.open({
                content: storage.t("Document:Form.VatSubmittedPopupWarning")
            });
        }

        // prepared to be overridden
        return true;
    }

    save = async (args: ISaveArgs = {}): Promise<IFormStorageSaveResult> => {
        const isConfirmed = await this.confirmationBeforeSave();
        const context = this.context as IAppContext;

        if (isConfirmed === false) {
            return null;

        }

        let forceVatLockOverrideDate: Date;

        if (!isCashBasisAccountingCompany(context) && this.props.storage.getEditOverride()?.id === FORCE_VAT_LOCK_OVERRIDE_PARAM) {
            forceVatLockOverrideDate = await this.props.promisedComponent.openComponent<Date>((onFinish) => {
                return (
                    <VatLockOverrideConfirmDialog onFinish={onFinish}
                                                  title={this.props.storage.t("Document:VatLockOverrideDialog.Title")}
                                                  fieldLabel={this.props.storage.t("Document:VatLockOverrideDialog.DateAccountingTransaction")}
                    />
                );
            });

            if (!forceVatLockOverrideDate) {
                return null;
            }
        }

        this.saveDraftDebounced.cancel();
        this.props.onBeforeSave?.();
        const entity = this.props.storage.data.entity;

        this.props.storage.clearEmptyLineItems("Items", true);

        const isNew = this.props.storage.data.bindingContext.isNew();

        const data = await this.prepareDataForSave(entity);
        let shouldReloadAccountAssignment = false;
        const argsWithData: ISaveArgs = {
            ...args,
            data,
            onBeforeExecute: async (batch: BatchRequest) => {
                const isNewBusinessPartner = !entity.BusinessPartner?.BusinessPartner?.Id;
                if (this.isReceivedDoc()) {
                    const paymentMethod = this.props.storage.getValueByPath("PaymentMethod", { useDirectValue: false });
                    if (paymentMethod === PaymentMethodCode.WireTransfer) {
                        addBusinessPartnerBankAccountRequest(this.props.storage, isNewBusinessPartner, batch);
                    }
                }
                addBusinessPartnerContactRequest(this.props.storage, isNewBusinessPartner, batch);

                if (isVisible({
                    info: this.props.storage.getInfo(this.props.storage.data.bindingContext.navigate(CREATE_RECEIPT_PATH)),
                    bindingContext: this.props.storage.data.bindingContext,
                    storage: this.props.storage,
                    context: this.props.storage.context
                }) && data[CREATE_RECEIPT_PATH]) {
                    addCreateReceiptRequest(this.props.storage, batch, data.CashBox?.Id);
                }

                // even though the result from sendAdditionalRequestForAccountAssignment
                // isn't referenced in any of the other requests, it is still better to add it to onBeforeExecute
                // to get error handling and have atomic behavior for all the batched requests
                shouldReloadAccountAssignment = this.hasAccountAssignment && (await sendAdditionalRequestForAccountAssignment(this.props.storage, data, batch, this.documentTypeCode));
                // Update minor assets with document

                // call onBeforeExecute for overridden child views
                this.onBeforeExecute(batch);
            },
            customResponseHandler: this.customResponseHandler,
            shouldUseCustomResponseHandler: this.shouldUseCustomResponseHandler,
            getCorrectErrorBc: this.getCorrectErrorBc
        };

        if (this.props.storage.getEditOverride()?.id === FORCE_VAT_LOCK_OVERRIDE_PARAM) {
            argsWithData.queryParams = {
                [FORCE_VAT_LOCK_OVERRIDE_PARAM]: true
            };

            if (forceVatLockOverrideDate) {
                argsWithData.queryParams[FORCE_VAT_LOCK_OVERRIDE_DATE_PARAM] = formatDateToDateString(forceVatLockOverrideDate);
            }
        }

        const result = await this.props.storage.save(argsWithData);

        if (!result) {
            this.props.onSaveFail?.();
            // for some reason, when confirmationBeforeSave dialog is used,
            // scroll moves back down after calling scrollPageUp => add setTimeout :/
            this.forceUpdate(() => setTimeout(this.scrollPageUp));
            return result;
        }

        this.props.storage.setCustomData({
            editOverride: null,
            usePrompt: null
        });

        if (isNew && !!data.DocumentDraft) {
            this.context.eventEmitter.emit(ContextEvents.RecalculateDrafts);
        }

        if (shouldReloadAccountAssignment) {
            invalidateAccountAssignments(this.props.storage);
        }

        setBusinessPartnerGroupStatus(this.props.storage, false);

        if (!args.skipAfterSave) {
            this.onAfterSave(isNew, false);
        }
        if (this.hasAccountAssignment) {
            correctAccountIds(this.props.storage);
        }
        this.forceUpdate(this.scrollPageDown);

        return result;
    };

    getExistingBusinessPartner = async (doc: IDocumentEntity, formStorage: FormStorage): Promise<IBusinessPartnerEntity> => {
        const docBP = doc.BusinessPartner;
        const name = docBP?.Name;
        if (!name) {
            // if name is not filled, we can't match the BusinessPartner at all...
            return null;
        }

        const legalNumber = docBP?.LegalNumber;
        let filter = `${BusinessPartnerEntity.Name} eq '${name}'`;

        if (legalNumber) {
            filter += ` AND ${BusinessPartnerEntity.LegalNumber} eq '${legalNumber}'`;
        } else {
            // without legal number, match by address,
            // if the address is not fully completed, match to bp with same name without address
            // https://solitea-cz.atlassian.net/browse/DEV-23952
            const countryCode = docBP?.Country?.Code;
            const postalCode = docBP?.PostalCode;
            const city = docBP?.City;
            const street = docBP?.Street;
            if (countryCode && postalCode && city && street) {
                filter += ` AND ${createPath(BusinessPartnerEntity.BillingAddress, BillingAddressEntity.CountryCode)} eq '${countryCode}'`;
                filter += ` AND ${createPath(BusinessPartnerEntity.BillingAddress, BillingAddressEntity.PostalCode)} eq '${postalCode}'`;
                filter += ` AND ${createPath(BusinessPartnerEntity.BillingAddress, BillingAddressEntity.City)} eq '${city}'`;
                filter += ` AND ${createPath(BusinessPartnerEntity.BillingAddress, BillingAddressEntity.Street)} eq '${street}'`;
            } else {
                filter += ` AND ${BusinessPartnerEntity.BillingAddress} eq null`;
            }
        }

        const existingBusinessPartnerRes = await formStorage.oData.getEntitySetWrapper(EntitySetName.BusinessPartners).query()
            .filter(filter)
            .expand(BusinessPartnerEntity.Contacts)
            .fetchData<IBusinessPartnerEntity[]>();

        const existingBusinessPartner = existingBusinessPartnerRes.value[0];

        if (existingBusinessPartner) {
            return existingBusinessPartner;
        }
        return null;
    };

    customResponseHandler: TCustomResponseHandler = async (response: IBatchResult[], args: IValidateAndSave, formStorage: FormStorage) => {
        const error = response[0] as IBatchResult<ODataError>;
        const businessPartnerAlreadyExists = error.body?._validationMessages?.find(message => BusinessPartnerSaveErrors.includes(message.code));
        if (businessPartnerAlreadyExists) {
            const existingBp = await this.getExistingBusinessPartner(args.entity, formStorage);
            if (existingBp?.Id) {
                const updatedDoc = {
                    ...args.entity,
                    BusinessPartner: {
                        ...args.entity.BusinessPartner,
                        // insert the navigation to already existing BusinessPartner
                        BusinessPartner: {
                            ...existingBp
                        }
                    }
                };
                return await formStorage.validateAndSave({
                    ...args,
                    entity: updatedDoc
                });
            }
        }
        if (this.props.customResponseHandler) {
            // propagate to props.customResponseHandler if not handled here
            return await this.props.customResponseHandler(response, args, formStorage);
        }

        return null;
    };

    shouldUseCustomResponseHandler: TShouldUseCustomResHandler = (response: IBatchResult[]) => {
        const error = response[0] as IBatchResult<ODataError>;
        const businessPartnerAlreadyExists = error.body?._validationMessages?.find(message => BusinessPartnerSaveErrors.includes(message.code));

        if (businessPartnerAlreadyExists) {
            // if user tries to save entity (probably draft) with "new" business partner, that already exists in database,
            // add that business partner navigation to the current document and try to save again
            return true;
        } else if (this.props.shouldUseCustomResponseHandler) {
            // propagate to props.shouldUseCustomResponseHandler if not handled here
            return this.props.shouldUseCustomResponseHandler(response);
        }

        return false;
    };

    getCorrectErrorBc = (args: IGetCorrectErrorBc): BindingContext => {
        const path = args.matchedBindingContext.getPath();
        // backend matches error to AccountAssignmentSelection but our fields is bound to AccountAssignmentSelection/AccountAssignment
        if (path === "AccountAssignmentSelection") {
            return args.matchedBindingContext.navigate("AccountAssignment");
            // BE LabelSelection to local LabelSelection/SelectionCode
        } else if (path === "LabelSelection") {
            return args.matchedBindingContext.navigate("SelectionCode");
        } else if (args.error.code === "CbaFiscalYearInvalid") {
            return args.matchedBindingContext.navigate(this.datePropForNumberRange);
        }

        return args.matchedBindingContext;
    };

    // recalculates all items, keepProp - base property name to be used as origin for recalculation
    recalculateAllItems = (keepProp: string, ignoreEmptyItems = true, reDefaultItemVat = false): void => {
        const { storage } = this.props;
        const bc = storage.data.bindingContext.navigate("Items");
        const items = (storage.data.entity.Items ?? []) as IRegularDocumentItemEntity[];

        items.forEach(item => {
            const itemBc = bc.addKey(item);
            if (reDefaultItemVat) {
                const vatBc = itemBc.navigate("Vat");
                // redefault Vat only if the item is not dirty -> if user somehow changed the item
                // (even if he doesn't change Vat directly), he might be satisfied with the previous default,
                // so we won't change it for him "silently" during all items recalculation
                if (!storage.isDirty(itemBc)) {
                    storage.setDefaultValue(vatBc);
                }
            }
            if (!ignoreEmptyItems || this.isItemAnyRecalcPropSet(bc.addKey(item))) {
                const newItemValues = this.recalculateLineItem(item, keepProp);
                if (newItemValues) {
                    storage.setValue(itemBc, newItemValues);
                }
            }
        });
    };

    recalculateLineItem = (lineItem: any, changedProp: string, decimalPlaces = 2) => {
        const nonNumericProps = ["Vat"];
        const newItem = { ...lineItem };
        const lineItemId = lineItem.Id ?? lineItem[BindingContext.NEW_ENTITY_ID_PROP];

        if (!nonNumericProps.includes(changedProp)) {
            newItem[changedProp] = parseFloat(newItem[changedProp]);

            if (isNaN(newItem[changedProp])) {
                newItem[changedProp] = 0;
            }
        }

        // todo what to do with big values? causes Infinity and NaN
        if (isNaN(parseFloat(newItem.Quantity))) {
            newItem.Quantity = 1;
        }

        if (lineItemId !== this._lastChangedLineItemId) {
            this._lineItemsLastChanged = null;
        }

        this._lastChangedLineItemId = lineItemId;
        const lastChangedValue = this._lineItemsLastChanged ?? this.defaultItemLastChangedProp;

        const args: IGetValueArgs = { storage: this.props.storage };
        const ignoreVat = isInAutomatedVatInclusionMode(args)
            || isDocumentReverseCharged(args);

        const newValues = calcLineItemValues({
            Quantity: newItem.Quantity,
            TransactionUnitPrice: newItem.TransactionUnitPrice,
            TransactionUnitPriceNet: newItem.TransactionUnitPriceNet,
            TransactionUnitPriceVat: newItem.TransactionUnitPriceVat,
            TransactionAmount: newItem.TransactionAmount,
            TransactionAmountNet: newItem.TransactionAmountNet,
            TransactionAmountVat: newItem.TransactionAmountVat,
            // if we select 'without VAT' (value is null) we want to calculate with 0 vat rate
            Vat: ignoreVat ? 0 : (newItem.Vat?.Code && newItem.Vat?.Rate) ?? 0
        }, changedProp, lastChangedValue, decimalPlaces);

        if (["TransactionUnitPrice", "TransactionAmount"].includes(changedProp)) {
            this._lineItemsLastChanged = "TransactionAmount";
        } else if (["TransactionUnitPriceVat", "TransactionAmountVat"].includes(changedProp)) {
            this._lineItemsLastChanged = "TransactionAmountVat";
        } else {
            // keep the original value, e.g. if user changes Vat twice, it should behave in a same way
        }

        newItem.Quantity = newValues.Quantity;
        newItem.TransactionUnitPrice = newValues.TransactionUnitPrice;
        newItem.TransactionUnitPriceVat = newValues.TransactionUnitPriceVat;
        newItem.TransactionUnitPriceNet = newValues.TransactionUnitPriceNet;
        newItem.TransactionAmount = newValues.TransactionAmount;
        newItem.TransactionAmountNet = newValues.TransactionAmountNet;
        newItem.TransactionAmountVat = newValues.TransactionAmountVat;

        if (!this.isRegularDocumentItem) {
            // for InternalDocument, remove props that are not used in its Items
            delete newItem.Quantity;
            delete newItem.TransactionUnitPrice;
            delete newItem.TransactionUnitPriceNet;
            delete newItem.TransactionUnitPriceVat;
            delete newItem.UnitPrice;
            delete newItem.UnitPriceVat;
            delete newItem.UnitPriceNet;
        }

        return newItem;
    };

    shouldDisableSaveButton = (): boolean => {
        return this.props.storage.isDisabled || !this.props.storage.data?.entity
            || !this.props.storage.data.entity.Items || this.props.storage.data.entity.Items.length === 0
            || this.props.storage.data.entity[DocumentEntity.Locks]?.length > 0;
    };

    canPostDocument = (): boolean => {
        return checkPermissionWithOwnPermission(this.props.storage, this.props.permissionContext, CompanyPermissionCode.DocumentPosting);
    };

    shouldDisableSavePostButton = (): boolean => {
        return this.shouldDisableSaveButton() || !this.canPostDocument();
    };

    renderCustomFooterButtons = (): React.ReactElement => {
        const customButtons = this.props.formProps?.customFooterButtons;
        return typeof customButtons === "function" ? customButtons(this.props.storage) : customButtons;
    };

    async handleDelete(): Promise<void> {
        await this.deleteDraft();
        await super.handleDelete();
    }

    createEntityCopy(args: ICopyEntityArgs = {}): IDocumentExtendedEntity {
        const EXCLUDED_PROPERTIES = [
            "Id", DocumentEntity.DocumentTypeCode, DocumentEntity.TransactionAmount,
            DocumentEntity.TransactionAmountDue, DocumentEntity.ClearedStatusCode,
            DocumentEntity.PostedStatusCode, DocumentEntity.DocumentStatusCode, DocumentEntity.Locks,
            DocumentEntity.VatStatementStatusCode,
            DocumentEntity.DocumentVatStatementStatusCode
        ];

        const ALLOWED_NAVIGATIONS = [
            "AccountAssignmentSelection", "BankAccount", "BusinessPartner", "Company", "Currency", "FiscalPeriod",
            "FiscalYear", "Items", "Labels", "NumberRange", "PaymentMethod", "TransactionCurrency", "VatClassificationSelection",
            "CashBox", "CbaCategory", "LabelSelection", "Vat", "CbaEntryType", "ExternalCorrectedDocumentType"
        ];

        if (args.includeAttachments) {
            ALLOWED_NAVIGATIONS.push("Attachments");
        }

        if (args.includeDocumentLinks) {
            ALLOWED_NAVIGATIONS.push("DocumentLinks");
        }

        const entity = args.entity ?? this.props.storage.data.entity;
        const copy: IDocumentExtendedEntity = {};
        const excludedProps = [...EXCLUDED_PROPERTIES, ...(args.excludeProps ?? [])];
        const isValidProp = (bc: BindingContext, value: TEntityPropValue, isItem?: boolean) => {
            const path = bc.getPath(true);
            const isNavigation = bc.isNavigation();
            const isAllowedNavigation = isNavigation && (isItem || (bc.getParent().isRoot() && ALLOWED_NAVIGATIONS.includes(path)));
            const isNotExcludedProperty = isItem || (!isNavigation && !excludedProps.includes(path));

            let isValid: boolean;

            if (args.skipInvalidProps && !isNavigation) {
                const info: IFieldInfo = {
                    id: bc.getPath(),
                    type: getFieldTypeFromProperty(bc.getProperty()),
                    bindingContext: bc,
                    validator: {
                        type: getValidatorTypeFromProperty(bc.getProperty())
                    }
                };
                const schema = Validator.createValidationObject(info, this.props.storage);
                isValid = schema.isValidSync(value);
            }

            return isAllowedNavigation || (isNotExcludedProperty && (!args.skipInvalidProps || isValid));
        };
        BindingContext.each(entity, this.props.storage.data.bindingContext, (value, bc) => {
            if (bc.isRoot()) {
                return true;
            }
            if (bc.getPath() === "NumberOurs" && (args.copyDataToNewDocument || !isObjectEmpty(entity.NumberRange))) {
                return false;
            }
            if (bc.getPath() === "Items") {
                const items = [];
                if (!value) {
                    return false;
                }
                for (const item of value as IEntity[]) {
                    const newItemId = item[BindingContext.NEW_ENTITY_ID_PROP];
                    const itemBc = !!newItemId ? bc.addKey(newItemId, true) : bc.addKey(item.Id);
                    const itemCopy = {} as IEntity;
                    for (const [key, val] of Object.entries(item)) {
                        if (key === BindingContext.NEW_ENTITY_ID_PROP) {
                            continue;
                        }
                        const isValid = isValidProp(itemBc.navigate(key), val, true);
                        if (isValid) {
                            (itemCopy as IEntity)[key] = val;
                        }
                    }
                    if (item.Id) {
                        itemCopy.Id = item.Id;
                    } else if (newItemId) {
                        itemCopy[BindingContext.NEW_ENTITY_ID_PROP] = newItemId;
                    }
                    if (newItemId) {
                        if (this.props.storage.isDirty(itemBc) || item.LinkedDocument?.Id || item[DRAFT_ITEM_ID_PATH]) {
                            items.push(itemCopy);
                        }
                    } else {
                        items.push(itemCopy);
                    }
                }
                value = items;
            }

            const isValid = isValidProp(bc, value);
            if (isValid) {
                (copy as IEntity)[bc.getPath(true)] = value;
            }

            return false;
        });

        // needed local context fields
        copy[SAVED_VATS_PATH] = entity[SAVED_VATS_PATH];

        return copy;
    }

    handleFilesUploaded = async (files: IFileMetadataEntity[]): Promise<void> => {
        const docData = this.props.storage.data.entity as IDocumentEntity;

        if (this.props.storage.data.bindingContext.isNew()) {
            // we always need to save the file to the draft, otherwise it would be lost when applyIsDocData is called
            await this.saveDraftAndApplyEffects();
        }

        // uploaded files are already stored => we can check entity directly
        if (docData.Attachments?.length === 1 && docData.Attachments[0].File?.IsIsdocReadable) {
            const confirmationPromise = this.props.confirmationDialog.open({
                content: this.props.storage.t("Document:Isdoc.Confirmation")
            });

            const shouldApply = await confirmationPromise;

            if (shouldApply) {
                // application of isDocData saves draft and redirects to correct URL
                await this.applyIsdocData(files[0]);
            }
        }
    };

    // Saves draft immediate and triggers callback when it's done
    handleRequestCreateDraft = async (callback: () => void): Promise<void> => {
        await this.saveDraftAndApplyEffects(true);
        callback();
    };

    handleCustomFileAction = ({ file, action }: { file: IFileMetadataEntity, action: string }): void => {
        if (action === APPLY_ISDOC_ACTION) {
            this.applyIsdocData(file);
        } else if (action === APPLY_ROSSUM_ACTION) {
            this.applyRossum(file);
        }
    };

    applyFileDataToDocument = async (file: IFileMetadataEntity, callback: (file: IFileMetadataEntity, wrapper: EntitySetWrapper, draftId: number) => Promise<boolean>, immediateBusy?: boolean): Promise<void> => {
        const hasDraft = hasSavedDraft(this.props.storage);

        this.props.storage.setBusy(true);

        if (!hasDraft || this.props.storage.isDirty()) {
            await this.saveDraftAndApplyEffects(true);
        }

        const batch = this.props.storage.oData.batch();

        batch.beginAtomicityGroup("group1");

        const draftId = this.props.storage.data.entity.DocumentDraft.Id;
        const wrapper = this.props.storage.oData.getEntitySetWrapper(EntitySetName.DocumentDrafts);

        const isOk = await callback(file, wrapper, draftId);

        if (isOk) {
            // TODO we could retrieve the new data directly in ODATA_ACTION_EXTRACT_ISDOC request
            // but OData class would have to add some support for it
            // and storage.init probably contains some other necessary code,
            // so it is easier to just call storage.reload
            // only problem is that we can have some hidden fields (not added in configuration, nor used as additional properties),
            // those fields won't be fetched here => some fields present in IsDoc will not make it to the saved document
            // so far handled e.g. for Items/Vat in DocumentCommonDefs.tsx form.additionalProperties.push
            await this.props.storage.reload({
                preserveInfos: true,
                withoutBusy: true
            });
        }
        this.props.storage.setBusy(false);
    };

    isDocCallback = async (file: IFileMetadataEntity, wrapper: EntitySetWrapper, draftId: number): Promise<boolean> => {
        const workDate = getWorkDateFromLocalStorage();

        try {
            await wrapper.action(OdataActionName.DocumentDraftExtractIsdoc, draftId, {
                FileMetadata: {
                    "@odata.id": `${EntitySetName.FilesMetadata}(${file.Id})`
                },
                WorkDate: workDate ? formatDateToDateString(workDate) : null
            } as IDocumentDraftExtractIsdocParameters);
        } catch (error) {
            this.props.storage.setFormAlert(getAlertFromError(error));
            return false;
        }

        return true;
    };

    applyIsdocData = async (file: IFileMetadataEntity): Promise<void> => {
        this.props.storage.setCustomData({ isApplyingIsdocToDocument: true });
        await this.applyFileDataToDocument(file, this.isDocCallback, true);
        this.props.storage.setCustomData({ isApplyingIsdocToDocument: false });
    };

    rossumCallback = async (file: IFileMetadataEntity, wrapper: EntitySetWrapper, draftId: number): Promise<boolean> => {
        this.props.storage.setBusy(false);

        try {
            const rossumFinished = await this.props.promisedComponent.openComponent<boolean>((onFinish) => {
                return (
                    <RossumProgressDialog draftId={draftId}
                                          file={file}
                                          onFinish={onFinish}
                    />
                );
            });

            this.props.storage.setBusy(true);

            return rossumFinished;

        } catch (error) {

            this.props.storage.setFormAlert(getAlertFromError(error));
            this.props.storage.refresh();
            return false;
        }
    };

    applyRossum = async (file: IFileMetadataEntity): Promise<void> => {
        const isConfirmed = await this.props.promisedComponent.openComponent<boolean>((onFinish) => {
            return (
                <RossumDialog onConfirm={(files: IFileMetadataEntity[]) => onFinish(files.length === 1)}
                              onCancel={() => onFinish(false)}
                              files={[file]}
                              isOnlyOneFileConfirmation
                />
            );
        });

        if (isConfirmed) {
            this.props.storage.setCustomData({ isApplyingRossumToDocument: true });
            await this.applyFileDataToDocument(file, this.rossumCallback);
            this.props.storage.setCustomData({ isApplyingRossumToDocument: false });
            this.props.storage.refresh(true);
        }

    };

    renderSaveButtons(): ReactElement {
        return (<>
            {!this.isPostable &&
                <Button hotspotId={"formSave"}
                        onClick={this.handleSaveClick}
                        isDisabled={this.shouldDisableSaveButton()}>
                    {this.saveButtonTranslation}</Button>}
            {this.isPostable &&
                <Button hotspotId={"formSaveAndPost"}
                        isDisabled={this.shouldDisableSavePostButton()}
                        onClick={this.handleSaveAndPostClick}>{this.saveButtonTranslation}</Button>}
        </>);
    }

    async handleCustomHeaderAction(actionId: string): Promise<void> {
        const saved = await this.props.onCustomHeaderAction?.(actionId, this.props.storage);
        if (saved) {
            this.saveDraftSync();
        }
    }

    getHeaderIcons(): IHeaderIcon[] {
        const icons = super.getHeaderIcons();
        const issuedDocTypesFiltered: DocumentTypeCode[] = IssuedDocumentTypes.filter(type => type !== DocumentTypeCode.OtherReceivable);
        const docTypesWithPdfExport: DocumentTypeCode[] = [...issuedDocTypesFiltered, DocumentTypeCode.InternalDocument];
        const docTypesWithSendExport: DocumentTypeCode[] = issuedDocTypesFiltered;
        const context = this.context as IAppContext;

        // at least one item has to exist for exports to be available
        if (this.props.storage.data.origEntity?.Items?.length > 0 && !this.props.storage.data.bindingContext.isNew() && !this.props.formProps?.isSimple) {
            const logActionSettings = {
                actionId: this.props.storage.id,
                actionType: ActionTypeCode.PDFExport,
                detail: `${getDefaultLogActionDetail(this.props.storage)} AND NumberOurs eq '${this.props.storage.data.entity.NumberOurs}'`
            };

            if (docTypesWithSendExport.includes(this.documentTypeCode)) {
                icons.push(
                    {
                        id: "send",
                        onClick: () => {
                            logAction(logActionSettings);
                            this.props.storage.setCustomData({ sendingMessageDialogOpen: true });
                            this.forceUpdate();
                        },
                        label: this.props.storage.t("Document:General.SendMessage"),
                        iconName: "Send",
                        ignoreMasterDisabled: true
                    }
                );
            }
            if (docTypesWithPdfExport.includes(this.documentTypeCode)) {
                const isInternalDocument = this.documentTypeCode === DocumentTypeCode.InternalDocument;
                const fnHandleClick = async (language: LanguageCode): Promise<void> => {
                    const docTypeName = this.documentTypeCode as string;

                    const pdfUrl = getInvoicePdfUrl({
                        documentType: docTypeName as EntityTypeName,
                        entityId: this.props.storage.data.bindingContext.getKey() as number,
                        companyId: this.getAppContext().getCompany().Id.toString(),
                        isForPrint: true,
                        language
                    });

                    this.props.storage.setBusy(true, false, true);

                    const file = await FileStorage.get(null, {
                        url: pdfUrl
                    });


                    this.props.storage.setBusy(false);
                    logAction(logActionSettings);
                    saveAs(file);
                };

                icons.push(
                    {
                        id: "exportPdf",
                        onClick: async () => {
                            fnHandleClick(LanguageCode.Czech);
                        },
                        label: this.props.storage.t("Components:Table.ExportToPDF"),
                        iconName: "ExportPdf",
                        ignoreMasterDisabled: true
                    }
                );

                if (!isInternalDocument) {
                    icons.push({
                        id: "exportPdfEnglish",
                        onClick: async () => {
                            fnHandleClick(LanguageCode.English);
                        },
                        label: this.props.storage.t("Components:Table.ExportToPDFEnglish"),
                        iconName: "ExportPdf",
                        ignoreMasterDisabled: true
                    });
                }
            }
            // hide for beta https://solitea-cz.atlassian.net/browse/DEV-14050
            // if (docTypesWithSendExport.includes(this.documentTypeCode)) {
            //     icons.push(
            //         {
            //             id: "webInvoice",
            //             onClick: () => {
            //                 logAction(logActionSettings);
            //                 window.open(`${ROUTE_WEBINVOICE}/${this.props.storage.data.entity.Id}`, "_blank");
            //             },
            //             label: this.props.storage.t("Document:General.WebInvoice"),
            //             iconName: "WebInvoice",
            //             ignoreMasterDisabled: true
            //         }
            //     );
            // }
        }

        // vat submissions lock force edit
        if (hasMetadataRule(this.props.storage, MetadataLockType.UpdatingRecordWithElectronicSubmission)
            && !this.props.storage.isReadOnly
        ) {
            icons.push({
                id: FORCE_VAT_LOCK_OVERRIDE_PARAM,
                onClick: async () => {
                    const context = this.context as IAppContext;
                    const allowedFields: string[] = [
                        DocumentEntity.BusinessPartner, DocumentEntity.VatClassificationSelection,
                        DocumentDraftEntity.Items
                    ];
                    const itemsAllowedFields: string[] = [
                        DocumentItemEntity.Amount, DocumentItemEntity.AmountNet, DocumentItemEntity.AmountVat,
                        DocumentItemEntity.TransactionAmount, DocumentItemEntity.TransactionAmountNet, DocumentItemEntity.TransactionAmountVat,
                        DocumentItemEntity.Vat
                    ];

                    // Country is supposed to still be non-editable
                    this.props.storage.data.metadata.metadata.DisabledPropertyRules[createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Country)] = this.props.storage.data.metadata.metadata.DisabledPropertyRules[DocumentEntity.BusinessPartner];

                    for (const allowedField of allowedFields) {
                        delete this.props.storage.data.metadata.metadata.DisabledPropertyRules[allowedField];
                    }

                    const itemsBc = this.props.storage.data.bindingContext.navigate(DocumentDraftEntity.Items);

                    for (const item of this.props.storage.data.entity.Items) {
                        const itemBc = itemsBc.addKey(item.Id);

                        for (const allowedField of itemsAllowedFields) {
                            delete this.props.storage.data.metadata.metadata.DisabledPropertyRules[itemBc.navigate(allowedField).getNavigationPath()];
                        }
                    }

                    this.props.storage.getBackendDisabledFieldMetadata.cache.clear();
                    this.props.storage.setCustomData({
                        usePrompt: {
                            dialogConfirmText: this.props.storage.t("Common:General.Confirm"),
                            dialogContent: this.props.storage.t("Document:Form.VatSubmittedOverrideLeavingWarning")
                        }
                    });

                    this.props.storage.setBusy(true);
                    // clear draft data when switching to edit override mode
                    // so that the newly enabled fields are not does not clash with the draft data,
                    // also draft is disabled in edit override mode to prevent any hard ux problems
                    await this.deleteDraft();
                    await this.props.storage.resetData();

                    this.props.storage.setEditOverride({
                        id: FORCE_VAT_LOCK_OVERRIDE_PARAM,
                        icon: EditLockIcon,
                        description: this.props.storage.t("Document:Form.VatSubmittedLockOverride")
                    });
                    this.props.storage.setBusy(false);
                    this.props.storage.refresh(true);
                },
                label: this.props.storage.t("Document:Form.EditVatSubmittedLock"),
                iconName: "EditLock"
            });

        }

        return icons;
    }

    handleCloseCbaCategoryDialog = (isConfirmed: boolean): void => {
        this.props.storage.setCustomData({
            isCbaCategoryDialogOpen: false
        });
        this.forceUpdate();
    };

    handleSuccessfulSending = (): void => {
        this.props.storage.setFormAlert({
            status: Status.Success,
            title: this.props.storage.t("Common:Validation.SuccessTitle"),
            subTitle: this.props.storage.t("Document:Form.InvoiceSuccessfullySent")
        });
        this.forceUpdate(this.scrollPageUp);
    };

    getFormSubtitle(): string {
        if (this.isCopy()) {
            return super.getFormSubtitle();
        }

        if (hasCorrectiveDocument(this.props.storage)) {
            return `/${this.props.storage.t("Document:CorrectiveDocument.Fixed")}/`;
        }

        return "";
    }

    handleCustomCbaCategoryChange = (e: ISmartFieldChange): void => {
        handleCustomCbaCategoryChange(e, this.props.storage);
    };

    renderCustomCbaCategoryDialog = (): React.ReactElement => {
        return (
            <CustomCategoryTemporalDialog storage={this.props.storage}
                                          onTemporalChange={this.handleCustomCbaCategoryChange}
                                          onClose={this.handleCloseCbaCategoryDialog}/>
        );
    };

    renderVatDialog = (): React.ReactElement => {
        const { storage } = this.props;
        if (!storage.getCustomData().isVatDialogOpened) {
            return null;
        }

        const { bindingContext } = storage.data;
        const lineId = _getId(storage.getCustomData().dialogCurrentLineItem);

        const _close = () => {
            const savedVatsBc = bindingContext.navigate(`Items(${lineId})/${ITEMS_VAT_DEDUCTION_PATH}`);
            storage.cancelFields([savedVatsBc]);
            storage.setCustomData({ isVatDialogOpened: false });
            this.forceUpdate();
        };

        const paths = getVatGroupRowsDef({
            prefix: `Items(${lineId})/VatClassificationSelection/VatClassification/`,
            isDocumentItem: true,
            addNonTaxAccount: this.isReceivedDoc()
        }).map(items => items.map(item => item.id));

        return (
            <TemporalFormDialog storage={this.props.storage}
                                width="450px"
                                onChange={this.handleLineItemsChange}
                                fieldPaths={paths}
                                title={this.props.storage.t("Document:Form.VatDialogTitle")}
                                onClose={_close}/>
        );
    };

    render() {
        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        return (
            <>
                {this.renderForm()}
                {this.hasAccountAssignment && <AccountAssignmentDialog storage={this.props.storage}
                                                                       onChange={this.handleChange}
                                                                       onTemporalChange={this.handleTemporalChange}
                                                                       onConfirm={this.updateItemsVat}/>}
                {this.renderCustomCbaCategoryDialog()}
                {this.renderVatDialog()}
                {this.props.storage.getCustomData().sendingMessageDialogOpen &&
                    <SendInvoiceDialog onAfterSend={this.handleSuccessfulSending} storage={this.props.storage}/>
                }
            </>
        );
    }
}

export default withPromisedComponent(withConfirmationDialog(withMinorAssetPairing(withTimeResolution(withAccruals()(withPermissionContext(withDomManipulator(DocumentFormView)))))));


//if we want to extend component that is using HOC (like withConfirmationDialog)
// we need to export it without the wrappers and apply the wrappers on the extended component
// TODO some TS trickery, that would enforce the wrapper being applied on the extended classes
export { DocumentFormView as DocumentFormViewForExtend };