import React from "react";
import { Button, IconButton } from "../button";
import { CaretIcon } from "../icon";
import { WithTranslation, withTranslation } from "react-i18next";
import { clamp, TabIndex } from "@utils/general";
import { EllipsisWrapper, StyledPaginator } from "./Paginator.styles";
import { ELLIPSIS_CHAR } from "../../constants";
import FocusManager, { FocusDirection, IFocusableItemProps } from "../focusManager/FocusManager";
import TestIds from "../../testIds";
import { ButtonSize } from "../button/Button.utils";

interface IProps extends WithTranslation {
    /** Page number from zero */
    page: number;
    pagesCount: number;
    onPageChange: (page: number) => void;
}

const MAX_ITEMS_COUNT = 7;

class Paginator extends React.PureComponent<IProps> {
    componentDidMount() {
        this.setPage(this.props.page);
    }

    componentDidUpdate(prevProps: IProps) {
        // prevent page from being outside of pagesCount
        this.setPage(this.props.page);
    }

    handlePreviousClick = () => {
        this.setPage(this.props.page - 1);
    };

    handleNextClick = () => {
        this.setPage(this.props.page + 1);
    };

    setPage = (page: number) => {
        const newPage = clamp(page, 0, this.props.pagesCount - 1);

        if (newPage !== this.props.page) {
            this.props.onPageChange(newPage);
        }
    };

    getMiddle(value: number) {
        return Math.floor(MAX_ITEMS_COUNT / 2);
    }

    getCurrentPagePos = (page: number, pagesCount: number, maxItems: number) => {
        const middle = this.getMiddle(maxItems);

        if (page < middle) {
            return page;
        } else if (pagesCount - page - 1 < middle) {
            return maxItems - 1 - (pagesCount - 1 - page);
        } else {
            return middle;
        }
    };

    renderButtons = (itemProps: IFocusableItemProps) => {
        const buttons = [];

        const itemsCount = Math.min(this.props.pagesCount, MAX_ITEMS_COUNT);
        const currentPagePos = this.getCurrentPagePos(this.props.page, this.props.pagesCount, itemsCount);

        buttons.push(this.renderButton(0, itemProps));


        for (let i = 1; i < itemsCount - 1; i++) {
            const diff = currentPagePos - i;
            const page = this.props.page - diff;
            const middle = this.getMiddle(itemsCount);

            if (this.props.pagesCount > MAX_ITEMS_COUNT &&
                ((i === 1 && this.props.page > middle) || (i === itemsCount - 2 && this.props.pagesCount - 1 - this.props.page > middle))) {
                buttons.push(this.renderDots(page));
            } else {
                buttons.push(this.renderButton(page, itemProps));
            }
        }

        buttons.push(this.renderButton(this.props.pagesCount - 1, itemProps));


        return buttons;
    };

    renderButton = (i: number, itemProps: IFocusableItemProps) => {
        const isSelected = this.props.page === i;

        return (
            <Button key={i}
                    onClick={() => {
                        this.setPage(i);
                    }}
                    tabIndex={isSelected ? TabIndex.NormalOrder : TabIndex.TemporaryDisabled}
                    passProps={itemProps}
                    isTransparent
                    size={ButtonSize.Small}
                    isBold={isSelected}
                    isActive={isSelected}>
                {i + 1}
            </Button>
        );
    };

    renderDots = (i: number) => {
        return (
            <EllipsisWrapper key={i}>
                {ELLIPSIS_CHAR}
            </EllipsisWrapper>
        );
    };

    render() {
        return (
            <FocusManager direction={FocusDirection.Horizontal}>
                {({ itemProps, wrapperProps }) => (
                    <StyledPaginator {...wrapperProps as any} tabIndex={TabIndex.Disabled}
                                     data-testid={TestIds.Paginator}>
                        <IconButton title={this.props.t("Components:Paginator.PreviousPage")}
                                    onClick={this.handlePreviousClick}
                                    isDisabled={this.props.page === 0}
                                    isDecorative>
                            <CaretIcon style={{ transform: "rotate(90deg)" }}/>
                        </IconButton>
                        {this.renderButtons(itemProps)}
                        <IconButton title={this.props.t("Components:Paginator.NextPage")}
                                    onClick={this.handleNextClick}
                                    isDisabled={this.props.page === this.props.pagesCount - 1}
                                    isDecorative>
                            <CaretIcon style={{ transform: "rotate(-90deg)" }}/>
                        </IconButton>
                    </StyledPaginator>
                )}
            </FocusManager>
        );
    }
}

export default withTranslation(["Components"])(Paginator);