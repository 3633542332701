import React, { Ref } from "react";
import {
    ClickCapture,
    IconWrapper,
    IStyledButtonProps,
    StyledButton,
    StyledButtonGroup,
    StyledIconButton,
    TextWrapper
} from "./Button.styles";
import { Direction, IconSize, Status, TextAlign } from "../../enums";
import TestIds from "../../testIds";
import Alert, { IAlertProps } from "../alert/Alert";
import { composeRefHandlers, getValue, TabIndex } from "@utils/general";
import { IPopperOptions } from "../popperWrapper";
import Tooltip from "../tooltip";
import { ColoredIcon } from "../icon";
import { THistoryLocation } from "../drillDown/DrillDown.utils";
import { Link } from "react-router-dom";
import { TFunctionResult } from "i18next";
import { HOTSPOT_ID_ATTR } from "../hotspots/Hotspots.utils";
import BusyIndicator from "../busyIndicator";
import { ButtonSize } from "./Button.utils";
import { KeyName } from "../../keyName";
import { ClickableWithAlertBase } from "../clickableWithAlert/ClickableWithAlert";
import { isExternalLink } from "../clickable/Clickable.utils";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { BusyIndicatorSize, BusyIndicatorType } from "../busyIndicator/BusyIndicator.utils";
import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";

export interface IProps {
    /** Renders html data-hotspotid attribute*/
    hotspotId?: string;

    /** Renders html id attribute*/
    id?: string;
    /** Defines whether the button should be rendered in light colors, suitable for dark backgrounds.
     * Light variant also has transparent background. */
    isLight?: boolean;
    /** Defines whether the button should have background and borders in default state. */
    isTransparent?: boolean;
    /** Makes the button transparent (even for the 'non transparent' variant) and removes selected/active state,
     * defaults padding to ButtonSize.Small */
    isDecorative?: boolean;

    /** Changes padding and/or font size. "Default" by default. */
    size?: ButtonSize;

    /** Makes the font bold, true by default */
    isBold?: boolean;
    /** Always use colors from default theme */
    ignoreTheme?: boolean;
    isDisabled?: boolean;
    isBusy?: boolean;
    /** Always use active look even when button is not clicked on */
    isActive?: boolean;
    isHover?: boolean;
    // TFunctionResult are translations which for some reason don't return string interface
    children?: string | number | TFunctionResult;
    /** Severity of the button (changes color theme) */
    status?: Status;
    /** When we need to prefix the text with icon in the default non icon Button */
    icon?: React.ReactElement;
    title?: string;
    /** Html button "type" attribute, "button" by default */
    type?: "button" | "submit" | "reset";
    tabIndex?: number;
    onClick?: (args: React.MouseEvent) => void;
    /** Renders <Link> instead of <button>. Use either "link" or  onClick */
    link?: THistoryLocation;
    onMouseDown?: (args: React.MouseEvent) => void;
    onMouseUp?: (args: React.MouseEvent) => void;
    onMouseOver?: (args: React.MouseEvent) => void;
    onKeyDown?: (args: React.KeyboardEvent) => void;
    onBlur?: (args: React.FocusEvent) => void;
    /** Alert that will be shown on button hover. */
    hoverAlert?: IAlertProps | (() => IAlertProps);
    /** Alert that will be shown on button click. */
    alert?: IAlertProps | (() => IAlertProps);
    alertPopperOptions?: IPopperOptions;
    testid?: string;
    passProps?: any;
    passRef?: React.Ref<HTMLButtonElement>;
    cursor?: string;
    style?: React.CSSProperties;
    className?: string;
    dontAddCompanyIdToLink?: boolean;
}

export const BUTTON_POPPER_TOOLTIP_OFFSET_Y = 7;

export interface IIconButtonProps extends Omit<IProps, "children"> {
    title: string;
    children: React.ReactElement;
}

const shouldIconBeLight = (icon: React.ReactElement, props: IProps) => {
    // icon should be light in light version of buttons or in emphasized button (not dec nor trans)
    return icon.props.isLight ?? (props.isLight || (!props.isDecorative && !props.isTransparent));
};

const getIconWidth = (icon: React.ReactElement, defaultWidth: string) => {
    return icon.props.width ?? icon.props.height ?? defaultWidth;
};

const getIconHeight = (icon: React.ReactElement, defaultHeight: string) => {
    return icon.props.height ?? icon.props.width ?? defaultHeight;
};


const getStyledButtonProps = (buttonProps: IProps): IStyledButtonProps => {
    return {
        $ignoreTheme: buttonProps.ignoreTheme,
        $status: buttonProps.status,
        $isLight: buttonProps.isLight,
        $isTransparent: buttonProps.isTransparent,
        $isDecorative: buttonProps.isDecorative,
        $isDisabled: buttonProps.isDisabled || buttonProps.isBusy,
        $isBusy: buttonProps.isBusy,
        $isActive: buttonProps.isActive,
        $isHover: buttonProps.isHover,
        $cursor: buttonProps.cursor,
        $isBold: buttonProps.isBold,
        $size: buttonProps.size,
        $hasIcon: !!buttonProps.icon
    };
};

const getPassedHtmlProps = (buttonProps: IProps, isDisabled = false): Partial<IProps> => {
    // safari doesn't focus button without tabIndex, it focuses parent element with tabindex directly, e.g.
    // Table instead of TableRowAction button https://solitea-cz.atlassian.net/browse/DEV-33137
    const defaultTabIndex = isDisabled ? TabIndex.Disabled : TabIndex.NormalOrder;
    return {
        tabIndex: buttonProps.tabIndex === undefined ? defaultTabIndex : buttonProps.tabIndex,
        onMouseDown: buttonProps.onMouseDown,
        onMouseUp: buttonProps.onMouseUp,
        onMouseOver: buttonProps.onMouseOver,
        onBlur: buttonProps.onBlur,
        style: buttonProps.style,
        className: buttonProps.className
    };
};

const getPassProps = (buttonProps: IProps, context: IAppContext, isExternal: boolean) => {
    const passProps = { ...buttonProps.passProps };

    if (buttonProps.link) {
        const link = buttonProps.isDisabled ? null : buttonProps.link;

        if (isExternal) {
            passProps.href = link;
            passProps.target = "_blank";
        } else {
            passProps.to = buttonProps.dontAddCompanyIdToLink ? link : addCompanyIdToUrl(link, context);
        }

        return passProps;
    } else {
        // add html button type attribute
        passProps.type = buttonProps.type ?? "button";
    }

    return passProps;
};

class Button extends React.PureComponent<IProps & WithDomManipulator> {
    static contextType = AppContext;

    static defaultProps: Partial<IProps> = {
        isBold: true,
        size: ButtonSize.Default,
        passProps: {}
    };

    _buttonRef = React.createRef<HTMLElement>();
    _clickCaptureRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.shouldRenderClickCapture) {
            this.updateClickCapturePosition();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.shouldRenderClickCapture) {
            this.updateClickCapturePosition();
        }
    }

    /** When button is disabled, it doesn't trigger "click" events.
     * => render ClickCapture outside of button and position it over it, so that we can capture clicks. */
    updateClickCapturePosition = (): void => {
        this.props.domManipulatorOrchestrator.registerCallback(
                () => {
                    const buttonEl = this._buttonRef.current;

                    return {
                        width: `${buttonEl.offsetWidth}px`,
                        height: `${buttonEl.offsetHeight}px`,
                        top: `${buttonEl.offsetTop}px`,
                        left: `${buttonEl.offsetLeft}px`
                    };
                },
                (position) => {
                    const clickCaptureEl = this._clickCaptureRef.current;

                    if (clickCaptureEl) {
                        clickCaptureEl.style.width = position.width;
                        clickCaptureEl.style.height = position.height;
                        clickCaptureEl.style.top = position.top;
                        clickCaptureEl.style.left = position.left;
                    }
                },
                [this._buttonRef, this._clickCaptureRef]
        );
    };

    get isDisabled(): boolean {
        return this.props.isDisabled || this.props.isBusy;
    }

    get shouldRenderClickCapture(): boolean {
        return this.props.alert && this.props.isDisabled;
    }

    handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === KeyName.Enter) {
            // pressing Enter triggers click on button
            // do not propagete it further to trigger e.g. dialog submit, etc...
            event.stopPropagation();
        }
        this.props.onKeyDown?.(event);
    };

    renderButton = (onClick: (event: React.MouseEvent) => void, handleRef: (element: HTMLButtonElement) => void): React.ReactElement => {
        let { passProps, ...props } = this.props;

        const getButton = (tooltipRef?: Ref<any>) => {
            const isIconButton = !!this.props.icon && !this.props.children;
            const isExternal = typeof this.props.link === "string" && isExternalLink(this.props.link);
            const buttonElement: React.ElementType = this.props.link && !this.isDisabled ? isExternal ? "a" : Link : "button";
            const ButtonComponent = !isIconButton ? StyledButton : StyledIconButton;
            let iconPart: React.ReactElement;

            passProps = getPassProps(this.props, this.context, isExternal);

            const { icon } = props;
            if (icon) {
                const iconSize = isIconButton ? IconSize.M : IconSize.S;

                const sharedIconProps = {
                    isLight: shouldIconBeLight(icon, this.props),
                    width: getIconWidth(icon, iconSize),
                    forceHover: this.props.isHover,
                    height: getIconHeight(icon, iconSize),
                    ignoreTheme: icon.props.ignoreTheme ?? this.props.ignoreTheme
                };

                // automatically set correct light prop for the icon, if the icon props doesn't contain light prop
                iconPart = props.status ? (
                        <ColoredIcon {...sharedIconProps}>{icon}</ColoredIcon>
                ) : React.cloneElement(this.props.icon, {
                    ...sharedIconProps,
                    preventHover: icon.props.preventHover ?? this.isDisabled,
                    isLightHover: icon.props.isLightHover ?? this.props.isDecorative
                });
                // icon is often used inside button and this way, dev doesn't have to always set the correct light prop directly on the icon

                // button with icon AND text looks a bit different - add wrapper
                if (!isIconButton) {
                    iconPart = (
                            <IconWrapper>
                                {iconPart}
                            </IconWrapper>
                    );
                }
            }

            return (
                    <>
                        <ButtonComponent data-testid={this.props.testid ?? TestIds.Button}
                                         id={this.props.id}
                                         ref={composeRefHandlers(this._buttonRef, handleRef, tooltipRef)}
                                         {...{ [HOTSPOT_ID_ATTR]: this.props.hotspotId }}
                                         as={buttonElement}
                                         title={props.hoverAlert && this.isDisabled ? null : props.title}
                                         {...getPassedHtmlProps(props, this.isDisabled)}
                                         {...getStyledButtonProps(props)}
                                         {...passProps}
                                         onClick={onClick}
                                         onKeyDown={this.handleKeyDown}
                                // disabled prevents hoverAlert events from being fired
                                         disabled={!props.hoverAlert && this.isDisabled}>
                            {iconPart}
                            {this.props.children &&
                                    <TextWrapper hasIcon={!!this.props.icon}>
                                        {this.props.children}
                                    </TextWrapper>
                            }
                            {this.props.isBusy && <BusyIndicator
                                    type={BusyIndicatorType.WithoutBackground}
                                    isInverse size={BusyIndicatorSize.XS}/>}
                        </ButtonComponent>
                        {/*disabled button doesn't fire events, but we need to capture "onClick" to show alert*/}
                        {this.shouldRenderClickCapture &&
                                <ClickCapture onClick={onClick} ref={this._clickCaptureRef}/>
                        }
                    </>
            );
        };

        if (this.props.hoverAlert) {
            return <Tooltip
                    noBackground={true}
                    content={<Alert {...getValue(this.props.hoverAlert)}/>}>
                {(ref) => getButton(ref)}
            </Tooltip>;
        }

        return getButton();
    };

    render() {
        return (
                <ClickableWithAlertBase {...this.props}
                                        renderClickable={this.renderButton}/>
        );
    }
}

const ExtendedButton = withDomManipulator(Button);
export { ExtendedButton as Button };

export class IconButton extends React.PureComponent<IIconButtonProps> {
    render() {
        return (
                <ExtendedButton {...this.props}
                                children={null}
                                icon={this.props.children}/>
        );
    }
}

export interface IButtonGroupProps {
    direction?: Direction;
    align?: TextAlign;
    className?: string;
    wrap?: string;
    style?: React.CSSProperties;
    children?: any;
}

export const ButtonGroup = React.forwardRef(
        ({
             direction = Direction.Horizontal, wrap = "nowrap", align = TextAlign.Center,
             className, style, children
         }: React.PropsWithChildren<IButtonGroupProps>, ref
        ) => {
            return (
                    <StyledButtonGroup data-testid={TestIds.ButtonGroup}
                                       direction={direction}
                                       align={align}
                                       ref={ref as any}
                                       wrap={wrap}
                                       className={className} style={style}>
                        {children}
                    </StyledButtonGroup>
            );
        });
